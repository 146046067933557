import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  getCartItemData,
  getIsStorePending
} from '../../store/cart';
import { BasePriceBlock } from '../BasePriceBlock';
import { PriceBlockProps } from './types';

export const PriceBlock: FC<PriceBlockProps> = ({
  id,
  price,
  oldPrice,
  discount,
  options,
  customClasses
}) => {
  const productItem = useSelector(getCartItemData(id));
  const isStorePending = useSelector(getIsStorePending);

  const priceResult = productItem?.price || price || 0;
  const oldPriceResult =
    productItem?.oldPrice || oldPrice || priceResult;
  const discountResult = productItem?.discount || discount;

  const isRedValue = Boolean(
    options?.isRedValue &&
      Math.abs(oldPriceResult - priceResult) > 0
  );

  return (
    <BasePriceBlock
      customClasses={customClasses}
      options={{
        ...options,
        isRedValue
      }}
      price={priceResult}
      oldPrice={oldPriceResult}
      discountPercent={discountResult}
      isLoading={isStorePending}
    />
  );
};
