export enum CartSummaryMockKey {
  bypass = 'bypass',
  basicList = 'basicList'
}

export type RequestHandlerWrapped<P, R> = {
  (params?: P): Promise<R>;
  abortRequest(): void;
};

export type CartSummaryMockStatusSetEvent = CustomEvent<{
  status?: CartSummaryMockKey;
}>;

export type AbortRequestMethodSetter = (
  setter: () => void
) => void;
