import { CART_TOKEN_LOCALSTORAGE_KEY } from '../../../config';

export const setCartToken = (token: string | undefined) => {
  if (token?.length) {
    localStorage.setItem(
      CART_TOKEN_LOCALSTORAGE_KEY,
      token
    );
  }
};
