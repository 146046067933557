import React, {
  FC,
  useEffect,
  useState,
  useRef,
  useMemo
} from 'react';
import clsx from 'clsx';
import {
  Icon,
  Skeleton,
  useSimpleTooltip
} from '@npm-registry/eapteka-ui';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { formatCurrency } from '../../helpers/price';
import { BuyButtonSkeleton } from '../BuyButtonSkeleton';
import { Loader } from '../Loader';
import type { BaseBuyStepperProps } from './types';
import Styles from './BaseBuyStepper.scss';

export const BaseBuyStepper: FC<BaseBuyStepperProps> = ({
  availableCount = Infinity,
  isRemoveAvailable = false,
  isUseLoading = true,
  isWithItemPrice = false,
  price = null,
  size = ComponentSizes.M,
  setCartItemQuantityHandler,
  increaseCartItemQuantityHandler,
  decreaseCartItemQuantityHandler,
  isLoading,
  isProductLoading = false,
  isUpdating,
  quantity,
  productItemErrors
}) => {
  // eslint-disable-next-line no-undef
  const animateTimeout = useRef<NodeJS.Timeout | null>(
    null
  );
  const isError = Boolean(productItemErrors.length);
  const [errorAnimateMessage, setErrorAnimateMessage] =
    useState('');

  const [tooltip, setTooltipParent] = useSimpleTooltip({
    label: isError
      ? productItemErrors[productItemErrors.length - 1]
          .message
      : errorAnimateMessage,
    placement: 'bottom'
  });

  useEffect(() => {
    if (animateTimeout.current) {
      clearTimeout(animateTimeout.current);
    }

    animateTimeout.current = setTimeout(() => {
      setErrorAnimateMessage(
        isError
          ? productItemErrors[productItemErrors.length - 1]
              .message
          : ''
      );
    }, 300);
  }, [isError, productItemErrors]);

  const priceByItem = useMemo(() => {
    if (price && quantity > 1) {
      return Math.ceil(price / quantity);
    }

    return null;
  }, [quantity, price]);

  const isXLarge = size === ComponentSizes.XL;
  const isLarge = size === ComponentSizes.L;
  const isMedium = size === ComponentSizes.M;
  const isSmall = size === ComponentSizes.S;

  const minQuantity = Number(isRemoveAvailable);

  if (isUseLoading && isLoading) {
    return <BuyButtonSkeleton size={size} />;
  }

  return (
    <div
      className={Styles.baseBuyStepperContainer}
      ref={setTooltipParent}
    >
      <div
        className={clsx(Styles.baseBuyStepper, {
          [Styles.baseBuyStepperXLarge]: isXLarge,
          [Styles.baseBuyStepperLarge]: isLarge,
          [Styles.baseBuyStepperMedium]: isMedium,
          [Styles.baseBuyStepperSmall]: isSmall,
          [Styles.baseBuyStepperError]: isError
        })}
      >
        <button
          className={clsx(Styles.baseBuyStepperButton, {
            [Styles.baseBuyStepperButtonXLarge]: isXLarge,
            [Styles.baseBuyStepperButtonLarge]: isLarge,
            [Styles.baseBuyStepperButtonMedium]: isMedium,
            [Styles.baseBuyStepperButtonSmall]: isSmall
          })}
          disabled={quantity <= minQuantity}
          onClick={decreaseCartItemQuantityHandler}
          name="decrease"
          type="button"
        >
          <Icon name="minus" />
        </button>
        {!isUpdating && (
          <input
            className={Styles.baseBuyStepperInput}
            type="text"
            min={minQuantity}
            value={quantity}
            onChange={(event) =>
              setCartItemQuantityHandler(
                Number(event.target.value)
              )
            }
          />
        )}
        {isUpdating && (
          <div
            className={Styles.baseBuyStepperUpdateLoader}
          >
            <Loader />
          </div>
        )}
        <button
          className={clsx(Styles.baseBuyStepperButton, {
            [Styles.baseBuyStepperButtonXLarge]: isXLarge,
            [Styles.baseBuyStepperButtonLarge]: isLarge,
            [Styles.baseBuyStepperButtonMedium]: isMedium,
            [Styles.baseBuyStepperButtonSmall]: isSmall,
            [Styles.baseBuyStepperButtonDisable]:
              quantity >= availableCount
          })}
          onClick={increaseCartItemQuantityHandler}
          //disabled={quantity >= availableCount}
          name="increase"
          type="button"
        >
          <Icon name="plus" />
        </button>
      </div>
      {Boolean(isError || errorAnimateMessage) && (
        <div
          className={clsx(Styles.baseBuyStepperTooltip, {
            [Styles.baseBuyStepperTooltipFadeIn]:
              isError && !errorAnimateMessage,
            [Styles.baseBuyStepperTooltipFadeOut]:
              !isError && errorAnimateMessage
          })}
        >
          {tooltip}
        </div>
      )}
      {isWithItemPrice && quantity > 1 && (
        <div className={Styles.baseBuyStepperPriceBlock}>
          {!isLoading &&
            !isProductLoading &&
            priceByItem && (
              <>{formatCurrency(priceByItem, true)}/шт.</>
            )}
          {(isLoading ||
            isProductLoading ||
            !priceByItem) && (
            <>
              <Skeleton
                className={Styles.baseBuyStepperPriceBlock}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
