import { v4 as uuid } from 'uuid';
import {
  CartError,
  ErrorType,
  ProductItemId
} from './types';

export const getNotEnoughInStockError = (
  id: ProductItemId,
  availableCount: number
): CartError => ({
  id: uuid(),
  productId: id,
  type: ErrorType.tooltip,
  message: `В наличии только ${availableCount} шт.`,
  expires: Date.now() + 1000
});

export const getERError = (
  id: ProductItemId
): CartError => ({
  id: uuid(),
  productId: id,
  type: ErrorType.tooltip,
  message: 'Нельзя изменить количество льготного товара',
  expires: Date.now() + 1000
});

export const getAvailableCountError = (
  id: ProductItemId
): CartError => ({
  id: uuid(),
  productId: id,
  type: ErrorType.tooltip,
  message: 'Количество товаров изменилось',
  expires: Date.now() + 1000
});
