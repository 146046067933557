import { CART_STORE_UPDATE_RESULT_EVENT_NAME } from '../../consts/cartStoreUpdateEvent';
import { CartStoreEvent } from '../../types/customEvents';
import { CartUpdateReceiveEvent } from '../../store/cart';

export const subscribeToCartUpdateResult = (
  eventListener: (
    event: CartStoreEvent<CartUpdateReceiveEvent>
  ) => void
) => {
  document.addEventListener(
    CART_STORE_UPDATE_RESULT_EVENT_NAME,
    eventListener
  );

  return () => {
    document.removeEventListener(
      CART_STORE_UPDATE_RESULT_EVENT_NAME,
      eventListener
    );
  };
};

export default subscribeToCartUpdateResult;
