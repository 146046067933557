import type {
  LocalStoragePreparedState,
  LocalStorageState
} from '../../types/cart';
import { CART_INFO_LOCALSTORAGE_KEY } from '../../config';

export const getLocalStorageNumberField = (
  field: number | undefined
) =>
  typeof field !== 'undefined' && field !== null
    ? field
    : null;

export const getCommonCartState =
  (): LocalStoragePreparedState => {
    const stateString = localStorage.getItem(
      CART_INFO_LOCALSTORAGE_KEY
    );
    // eslint-disable-next-line prefer-const
    let resultState: LocalStoragePreparedState = {
      productsCount: null,
      productsItemsSumCount: null,
      products: []
    };

    try {
      const data = JSON.parse(
        stateString
      ) as Partial<LocalStorageState>;

      resultState.productsCount =
        getLocalStorageNumberField(data?.productsCount);
      resultState.productsItemsSumCount =
        getLocalStorageNumberField(
          data?.productsItemsSumCount
        );

      if (data?.products && Array.isArray(data.products)) {
        resultState.products = data.products;
      }
    } catch (err) {
      console.info('get common cart error: ', err);
    }

    return resultState;
  };

export const getCartProductsCount = (): number | null =>
  getCommonCartState().productsCount;

export const getCartProductsItemsSumCount = ():
  | number
  | null => getCommonCartState().productsItemsSumCount;
