import { CART_STORE_UPDATE_EVENT_NAME } from '../../consts/cartStoreUpdateEvent';
import {
  CartItemReceiveEvent,
  ProductItemId
} from '../../store/cart';
import { CartStoreEvent } from '../../types/customEvents';
import { sendCustomEvent } from '../sendCustomEvent';
import {
  HandlesKeys,
  SendHandlesEventPromise
} from './types';

export const sendHandlesEvent = <
  EventData extends { id: ProductItemId }
>(
  key: HandlesKeys,
  updateData: EventData
): SendHandlesEventPromise => {
  const traceId = sendCustomEvent<EventData>(
    key,
    updateData
  );

  const result = new Promise((resolve, reject) => {
    const handleCallBack = (
      event: CartStoreEvent<CartItemReceiveEvent>
    ) => {
      if (event.detail.traceId === traceId) {
        if (event.detail.error) {
          reject(event.detail.data);
        } else {
          resolve(event.detail.data);
        }

        document.removeEventListener(
          CART_STORE_UPDATE_EVENT_NAME,
          handleCallBack
        );
      }
    };

    document.addEventListener(
      CART_STORE_UPDATE_EVENT_NAME,
      handleCallBack
    );
  }) as SendHandlesEventPromise;

  result.traceId = traceId;

  return result;
};
