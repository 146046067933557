import { v4 as uuid } from 'uuid';
import { CART_STORE_UPDATE_EVENT_NAME } from '../../../consts/cartStoreUpdateEvent';
import {
  CartStoreReducersKeys,
  ProductItemId
} from '../types';

export const sendCartEvent = (
  reducerKey: CartStoreReducersKeys,
  id: ProductItemId | null = null,
  newQuantity: number | null = null,
  traceId: string = uuid()
) => {
  document.dispatchEvent(
    new CustomEvent(CART_STORE_UPDATE_EVENT_NAME, {
      detail: {
        traceId,
        data: {
          action: reducerKey,
          newQuantity,
          id
        }
      }
    })
  );
};
