import React, { FC } from 'react';
import clsx from 'clsx';
import { Icon } from '@npm-registry/eapteka-ui';
import { BaseCommonCardItemStateProps } from '../../types/BaseCommonCardItemStateProps';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { formatCurrency } from '../../helpers/price';
import { BuyButtonSkeleton } from '../BuyButtonSkeleton';
import { Loader } from '../Loader';
import Styles from './CartPageStepper.scss';

export const CartPageStepper: FC<
  BaseCommonCardItemStateProps
> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  isWithItemPrice,
  price,
  customHandlers,
  eventsHandlers
}) => {
  const {
    setCartItemQuantityHandler,
    increaseCartItemQuantityHandler,
    decreaseCartItemQuantityHandler,
    isLoading,
    isUpdating,
    quantity,
    price: currentPrice,
    productItemErrors
  } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers,
    price
  });

  const isError = productItemErrors.length;

  if (isLoading) {
    return <BuyButtonSkeleton size={ComponentSizes.S} />;
  }

  return (
    <div className={Styles.cartPageStepperContainer}>
      <div
        className={clsx(Styles.cartPageStepper, {
          [Styles.cartPageSteppeError]: isError
        })}
      >
        <button
          className={clsx(
            Styles.cartPageStepperButton,
            Styles.cartPageStepperButtonDown
          )}
          disabled={quantity <= 1}
          onClick={decreaseCartItemQuantityHandler}
          name="decrease"
          type="button"
        >
          <Icon name="minus" />
        </button>
        {!isUpdating && (
          <input
            className={Styles.cartPageStepperInput}
            type="text"
            min={1}
            value={quantity}
            onChange={(event) =>
              setCartItemQuantityHandler(
                Number(event.target.value)
              )
            }
          />
        )}
        {isUpdating && <Loader />}
        <button
          className={clsx(
            Styles.cartPageStepperButton,
            Styles.cartPageStepperButtonUp
          )}
          onClick={increaseCartItemQuantityHandler}
          disabled={quantity >= availableCount}
          name="increase"
          type="button"
        >
          <Icon name="plus" />
        </button>
      </div>
      {isWithItemPrice && (
        <div className={Styles.cartPageStepperPriceBlock}>
          {currentPrice && (
            <>{formatCurrency(currentPrice, true)}/шт.</>
          )}
        </div>
      )}
    </div>
  );
};
