export const CART_STORE_UPDATE_EVENT_NAME =
  'appCommonCartStoreUpdate';

export const CART_STORE_UPDATE_RESULT_EVENT_NAME =
  'appCommonCartStoreUpdateResult';

export const CART_STORE_ADD_ITEM_EVENT_NAME =
  'appCommonCartStoreAddItem';

export const CART_STORE_REMOVE_ITEM_EVENT_NAME =
  'appCommonCartStoreRemoveItem';

export const CART_STORE_SET_ITEM_QUANTITY_EVENT_NAME =
  'appCommonCartStoreSetItemQuantity';

export const CART_STORE_INCREASE_ITEM_QUANTITY_EVENT_NAME =
  'appCommonCartStoreIncreaseItemQuantity';

export const CART_STORE_DECREASE_ITEM_QUANTITY_EVENT_NAME =
  'appCommonCartStoreDecreaseItemQuantity';

export const CART_STORE_ITEM_UPDATE_RECEIVE_EVENT_NAME =
  'appCommonCartStoreDecreaseItemQuantity';

export const CART_STORE_REMOVE_ITEMS_GROUP_EVENT_NAME =
  'appCommonCartStoreRemoveItemsGroup';

export const CART_STORE_CLEAR_EVENT_NAME =
  'appCommonCartStoreClear';

export const CART_STORE_CLEAR_FINALLY_EVENT_NAME =
  'appCommonCartStoreClearFinally';

export const BX_CART_UPDATE = 'BXCartUpdate';
