import React, { FC, useEffect, useRef } from 'react';
import { useCommonCardBaseState } from '../../hooks/useCommonCardBaseState';
import { ICartCounterProps } from './types';

export const CartCounter: FC<ICartCounterProps> = ({
  className,
  classNameActive
}) => {
  const countContainerRef = useRef<HTMLDivElement>(null);
  const lastCountState = useRef(0);
  const { quantityLabel, cartQuantity } =
    useCommonCardBaseState();

  useEffect(() => {
    let animationTimout: NodeJS.Timeout | null = null;

    if (
      cartQuantity > lastCountState.current &&
      countContainerRef.current
    ) {
      countContainerRef.current.classList.add(
        classNameActive
      );

      animationTimout = setTimeout(() => {
        if (countContainerRef.current) {
          countContainerRef.current.classList.remove(
            classNameActive
          );
        }
      }, 300);
    }

    lastCountState.current = cartQuantity;

    return () => {
      if (animationTimout) {
        clearTimeout(animationTimout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartQuantity]);

  if (!cartQuantity) {
    return null;
  }

  return (
    <div ref={countContainerRef} className={className}>
      {quantityLabel}
    </div>
  );
};
