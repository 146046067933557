// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button.LZMbqf9{display:inline-flex;width:100%;font-family:var(--display-font-family);font-size:16px;font-weight:600;line-height:20px}button.WaJYbca{height:56px;padding:16px;font-size:18px;line-height:24px}button.A4bK77p{height:48px;padding:14px 16px}button.l-DpsnA{height:40px;padding:10px 16px}button.A28-eIo{height:32px;padding:6px 16px}button._0IrS0QQ{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseBuyButton": "LZMbqf9",
	"baseBuyButtonXLarge": "WaJYbca",
	"baseBuyButtonLarge": "A4bK77p",
	"baseBuyButtonMedium": "l-DpsnA",
	"baseBuyButtonSmall": "A28-eIo",
	"baseBuyButtonAutoHeight": "_0IrS0QQ"
};
export default ___CSS_LOADER_EXPORT___;
