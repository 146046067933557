import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { BuyButtonAnimated } from './BuyButtonAnimated';
import { BuyButtonAnimatedProps } from './types';

export const BuyButtonAnimatedProvider: FC<
  BuyButtonAnimatedProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <BuyButtonAnimated {...transferProps} />
  </Provider>
);
