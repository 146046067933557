import React, { FC } from 'react';
import { useCommonCardBaseState } from '../../hooks/useCommonCardBaseState';

export const CartCounterNumber: FC = () => {
  const { quantityLabel, cartQuantity } =
    useCommonCardBaseState();

  if (!cartQuantity) {
    return null;
  }

  return <>{quantityLabel}</>;
};
