import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAvailableCountError,
  getERError,
  getNotEnoughInStockError
} from '../store/cart/errors';
import type { BaseCommonCardItemStateProps } from '../types/BaseCommonCardItemStateProps';
import {
  getCartItemData,
  getCartItemErrorsList,
  getIsStoreInit,
  getIsStorePending,
  ProductItemId,
  addItemToCart,
  removeItemFromCart,
  setCartItemQuantity,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  pushCartError,
  getOldProductItemState
} from '../store/cart';

export interface UseCommonCardItemStateProps
  extends BaseCommonCardItemStateProps {
  price?: number;
  oldPrice?: number;
}

export const useCommonCardItemState = ({
  id,
  price,
  oldPrice,
  unitQuantity = 1,
  availableCount = Infinity,
  customHandlers,
  eventsHandlers
}: UseCommonCardItemStateProps) => {
  const dispatch = useDispatch();
  const isStoreInit = useSelector(getIsStoreInit);
  const isStorePending = useSelector(getIsStorePending);
  const productItem = useSelector(getCartItemData(id));
  const oldProductItem = useSelector(
    getOldProductItemState(id)
  );
  const productItemErrors = useSelector(
    getCartItemErrorsList(id)
  );
  const isDisabled = productItem?.isBenefitERecipe;

  const currentQuantity = productItem?.quantity || 0;

  useEffect(() => {
    let firstLoadError = () => {
      if (
        !productItem ||
        !id ||
        availableCount === Infinity
      ) {
        return;
      }

      if (
        typeof oldProductItem !== 'undefined' &&
        oldProductItem > availableCount
      ) {
        dispatch(pushCartError(getAvailableCountError(id)));
      }

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      firstLoadError = () => {};
    };

    firstLoadError();
  }, [
    dispatch,
    id,
    productItem,
    availableCount,
    oldProductItem
  ]);

  useEffect(() => {
    if (
      productItem &&
      availableCount &&
      productItem?.quantity > availableCount
    ) {
      dispatch(
        setCartItemQuantity({
          id,
          newQuantity: availableCount
        })
      );
      dispatch(pushCartError(getAvailableCountError(id)));
    }
  }, [dispatch, availableCount, productItem, id]);

  const validateIncreaseCount = useCallback(
    (
      newQuantity: number,
      validationSuccessHandler: () => void,
      validationErrorHandler?: () => void
    ) => {
      if (availableCount >= newQuantity) {
        validationSuccessHandler();
      } else {
        if (validationErrorHandler) {
          validationErrorHandler();
        }

        dispatch(
          pushCartError(
            getNotEnoughInStockError(id, availableCount)
          )
        );
      }
    },
    [dispatch, availableCount, id]
  );

  const addCartItemHandler = useCallback(() => {
    const newQuantity = currentQuantity + unitQuantity;

    validateIncreaseCount(newQuantity, () => {
      if (customHandlers?.addCartItem) {
        customHandlers?.addCartItem({
          id,
          newQuantity
        });
      } else {
        dispatch(
          addItemToCart({
            id,
            newQuantity
          })
        );
      }

      if (eventsHandlers?.addCartItem) {
        eventsHandlers?.addCartItem({
          id,
          newQuantity
        });
      }
    });
  }, [
    dispatch,
    validateIncreaseCount,
    id,
    customHandlers,
    eventsHandlers,
    currentQuantity,
    unitQuantity
  ]);

  const removeItemFromCartHandler = () => {
    if (customHandlers?.removedItemFromCart) {
      customHandlers?.removedItemFromCart({ id });
    } else if (customHandlers?.removeItemFromCart) {
      customHandlers?.removeItemFromCart({ id });
    } else {
      dispatch(removeItemFromCart({ id }));
    }

    if (eventsHandlers?.removedItemFromCart) {
      eventsHandlers.removedItemFromCart({ id });
    } else if (eventsHandlers?.removeItemFromCart) {
      eventsHandlers?.removeItemFromCart({ id });
    }
  };

  // TODO: нужно понять будем ли мы округлять значение
  //  до unitQuantity в случае его наличия и в какую сторону
  const setCartItemQuantityHandler = (
    newQuantity: number
  ) => {
    validateIncreaseCount(
      newQuantity,
      () => {
        if (
          newQuantity === 0 &&
          customHandlers?.removedItemFromCart
        ) {
          customHandlers?.removedItemFromCart({ id });
        } else if (customHandlers?.setCartItemQuantity) {
          customHandlers?.setCartItemQuantity({
            id,
            newQuantity
          });
        } else {
          dispatch(
            setCartItemQuantity({
              id,
              newQuantity
            })
          );
        }

        if (
          newQuantity === 0 &&
          eventsHandlers?.removedItemFromCart
        ) {
          eventsHandlers?.removedItemFromCart({ id });
        } else if (eventsHandlers?.setCartItemQuantity) {
          eventsHandlers?.setCartItemQuantity({
            id,
            newQuantity
          });
        }
      },
      () => {
        if (
          newQuantity >= availableCount &&
          currentQuantity !== availableCount
        ) {
          dispatch(
            setCartItemQuantity({
              id,
              newQuantity: availableCount
            })
          );
        }
      }
    );
  };

  const increaseCartItemQuantityHandler = () => {
    const newQuantity = currentQuantity + unitQuantity;

    const handler = (
      productId: ProductItemId,
      quantity: number
    ) => {
      if (customHandlers?.increaseCartItemQuantity) {
        customHandlers?.increaseCartItemQuantity({
          id: productId,
          newQuantity: quantity
        });
      } else {
        dispatch(
          increaseCartItemQuantity({
            id: productId,
            newQuantity: quantity
          })
        );
      }

      if (eventsHandlers?.increaseCartItemQuantity) {
        eventsHandlers?.increaseCartItemQuantity({
          id: productId,
          newQuantity: quantity
        });
      }
    };

    validateIncreaseCount(newQuantity, () => {
      handler(id, newQuantity);
    });
  };

  const decreaseCartItemQuantityHandler = () => {
    const newQuantity = currentQuantity - unitQuantity;

    if (
      newQuantity === 0 &&
      customHandlers?.removedItemFromCart
    ) {
      customHandlers?.removedItemFromCart({ id });
    } else if (customHandlers?.decreaseCartItemQuantity) {
      customHandlers?.decreaseCartItemQuantity({
        id,
        newQuantity
      });
    } else {
      dispatch(
        decreaseCartItemQuantity({
          id,
          newQuantity
        })
      );
    }

    if (
      newQuantity === 0 &&
      eventsHandlers?.removedItemFromCart
    ) {
      eventsHandlers?.removedItemFromCart({ id });
    } else if (eventsHandlers?.decreaseCartItemQuantity) {
      eventsHandlers?.decreaseCartItemQuantity({
        id,
        newQuantity
      });
    }
  };

  const showDisableError = () => {
    dispatch(pushCartError(getERError(id)));
  };

  return {
    isDisabled,
    isUpdating: false,
    isLoading: !isStoreInit || Boolean(isStorePending),
    productItemErrors,

    price: productItem?.price || price || 0,
    oldPrice: productItem?.oldPrice || oldPrice || 0,
    quantity: currentQuantity,

    ...(isDisabled
      ? {
          addCartItemHandler: showDisableError,
          removeItemFromCartHandler: showDisableError,
          setCartItemQuantityHandler: showDisableError,
          increaseCartItemQuantityHandler: showDisableError,
          decreaseCartItemQuantityHandler: showDisableError
        }
      : {
          addCartItemHandler,
          removeItemFromCartHandler,
          setCartItemQuantityHandler,
          increaseCartItemQuantityHandler,
          decreaseCartItemQuantityHandler
        })
  };
};
