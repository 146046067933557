import React, { FC } from 'react';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BuyButtonSkeleton } from '../BuyButtonSkeleton';
import { BuyButtonSuccess } from '../BuyButtonSuccess';
import { BaseBuyButton } from '../BaseBuyButton';
import { Loader } from '../Loader';
import { BuyButtonAnimatedProps } from './types';
import Styles from './BuyButtonAnimated.scss';

export const BuyButtonAnimated: FC<
  BuyButtonAnimatedProps
> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  customHandlers,
  eventsHandlers,
  isWithAutoHeight,
  size = ComponentSizes.M,
  label = 'В корзину'
}) => {
  const {
    addCartItemHandler,
    removeItemFromCartHandler,
    isLoading,
    isUpdating,
    quantity
  } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers
  });

  if (isLoading) {
    return (
      <BuyButtonSkeleton
        isWithAutoHeight={isWithAutoHeight}
        size={size}
      />
    );
  }

  const isItemInCart = quantity > 0;

  return (
    <div className={Styles.buyButtonAnimated}>
      {!isItemInCart && (
        <BaseBuyButton
          onClick={addCartItemHandler}
          isWithAutoHeight={isWithAutoHeight}
          size={size}
        >
          {!isUpdating && label}
          {isUpdating && <Loader />}
        </BaseBuyButton>
      )}
      {isItemInCart && (
        <BuyButtonSuccess
          onClick={removeItemFromCartHandler}
          isWithAutoHeight={isWithAutoHeight}
          size={size}
        />
      )}
    </div>
  );
};
