import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { BuyButtonWithStepper } from './BuyButtonWithStepper';
import { BuyButtonWithStepperProps } from './types';

export const BuyButtonWithStepperProvider: FC<
  BuyButtonWithStepperProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <BuyButtonWithStepper {...transferProps} />
  </Provider>
);
