import {
  CartStore,
  ProductItemId,
  CartStoreReducersKeys
} from './types';
import { sendCartEvent } from './helpers/sendCartEvent';

export const updateCartItem = (
  state: CartStore,
  id: ProductItemId,
  newQuantity: number,
  reducerKey?: CartStoreReducersKeys,
  traceId?: string
) => {
  const itemsList = state?.products || [];
  const currentItemIndex = itemsList.findIndex(
    ({ id: itemId }) => itemId === id
  );

  if (
    currentItemIndex !== -1 &&
    itemsList[currentItemIndex]
  ) {
    itemsList[currentItemIndex].quantity = newQuantity;
  } else {
    itemsList.push({
      id,
      quantity: newQuantity,
      price: null,
      oldPrice: null,
      discount: null,
      isBenefitERecipe: null
    });
  }

  if (reducerKey) {
    sendCartEvent(reducerKey, id, newQuantity, traceId);
  }
};
