import { ERRORS_CLEAR_INTERVAL } from '../../../config';
import type { TRootState } from '../../rootReducer';
import type { TStore } from '../../store';
import { getErrorsList } from '../selectors';
import { cartActions } from '../slice';

export const errorClearInterval = (store: TStore) => {
  setInterval(() => {
    const currentDate = Date.now();
    const currentError = getErrorsList(
      store.getState() as TRootState
    );

    currentError.forEach(({ id, expires }) => {
      if (expires <= currentDate) {
        store.dispatch(cartActions.removeCartErrorByID(id));
      }
    });
  }, ERRORS_CLEAR_INTERVAL);
};
