import {
  CartSummaryMockStatusSetEvent,
  CartSummaryMockKey,
  RequestHandlerWrapped
} from './types';
import { mocksMap } from './consts';

// Событие по которому можно менять статус мока
// document.dispatchEvent(
// new CustomEvent('appCommonCartSummaryMockStatusSet', { detail: { status: 'basicList' } })
// );
document.addEventListener(
  'appCommonCartSummaryMockStatusSet',
  (event: CartSummaryMockStatusSetEvent) => {
    const status = event?.detail?.status;

    if (status && status in CartSummaryMockKey) {
      localStorage.setItem(
        'appCommonCartSummaryMockStatus',
        status
      );
    }
  }
);

const getMockStatus = () => {
  const status = localStorage.getItem(
    'appCommonCartSummaryMockStatus'
  );

  if (status && status in CartSummaryMockKey) {
    return status as CartSummaryMockKey;
  }

  return null;
};

export const mockSelector = async <P, R>(
  request: RequestHandlerWrapped<P, R>
) => {
  const status = getMockStatus();

  if (mocksMap[status] && mocksMap[status] !== null) {
    return mocksMap[status] as (typeof mocksMap)[Exclude<
      CartSummaryMockKey,
      CartSummaryMockKey.bypass
    >];
  }

  return request();
};
