import React, { FC } from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import { useCommonCardBaseState } from '../../hooks/useCommonCardBaseState';
import { TabBarCart } from '../TabBarCart';
import { MobileMenuCartLinkProps } from './types';
import Styles from './MobileMenuCartLink.scss';

export const MobileMenuCartLink: FC<
  MobileMenuCartLinkProps
> = ({ cartLinkPath = '/personal/cart/' }) => {
  const { cartTitle } = useCommonCardBaseState();

  return (
    <a
      className={Styles.mobileMenuCartLink}
      href={cartLinkPath}
    >
      <span className={Styles.mobileMenuCartLinkIcon}>
        <Icon name="cart" size="m" />
        <TabBarCart />
      </span>
      <span className={Styles.mobileMenuCartLinkLabel}>
        {cartTitle}
      </span>
    </a>
  );
};
