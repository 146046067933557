import React, { FC } from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BuyButtonSuccessProps } from './types';
import Styles from './BuyButtonSuccess.scss';

export const BuyButtonSuccess: FC<
  BuyButtonSuccessProps
> = ({
  onClick,
  size = ComponentSizes.M,
  isWithAutoHeight = false
}) => (
  <button
    className={clsx(Styles.buyButtonSuccess, {
      [Styles.buyButtonSuccessXLarge]:
        size === ComponentSizes.XL,
      [Styles.buyButtonSuccessLarge]:
        size === ComponentSizes.L,
      [Styles.buyButtonSuccessMedium]:
        size === ComponentSizes.M,
      [Styles.buyButtonSuccessSmall]:
        size === ComponentSizes.S,
      [Styles.buyButtonSuccessAutoHeight]: isWithAutoHeight
    })}
    onClick={onClick}
  >
    <Icon
      className={Styles.buyButtonSuccessIcon}
      name="checkLarge"
      size="m"
    />
  </button>
);
