import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { IconBuyButton } from './IconBuyButton';
import { IconBuyButtonProps } from './types';

export const IconBuyButtonProvider: FC<
  IconBuyButtonProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <IconBuyButton {...transferProps} />
  </Provider>
);
