// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8g0-PYn{display:inline-block;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyButtonAnimated": "_8g0-PYn"
};
export default ___CSS_LOADER_EXPORT___;
