// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wM\\+whAZ{position:relative;display:inline-flex;flex-shrink:0;justify-content:center;align-items:flex-end;width:78px;height:56px;line-height:56px;color:#333f48;background:#fff;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out}.NZTGDG-{display:flex;flex-direction:column;justify-content:center;align-items:center;font-family:var(--display-font-family);font-size:14px;font-weight:600;line-height:16px;text-decoration:none;color:#333f48}.NZTGDG-:hover{color:#8168f0}.kvzB-6M{position:relative;display:inline-block;width:24px;margin-bottom:4px}.fy88QUg{position:absolute;top:-6px;left:calc(100% - 6px);min-width:8px;height:16px;padding:0 4px;font-family:var(--control-font-family);font-size:12px;font-weight:600;line-height:16px;text-align:center;color:var(--appCommonCardHeadCartCounterColor);border-radius:50px;background:var(--appCommonCardHeadCartCounterBackground)}.PYQHADt{animation:YVqa6fm 300ms linear}@keyframes YVqa6fm{0%{scale:0}100%{scale:1}}.VanZxeL{margin-bottom:1px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCart": "wM+whAZ",
	"headerCartButton": "NZTGDG-",
	"headerCartButtonCounterContainer": "kvzB-6M",
	"headerCartButtonCounter": "fy88QUg",
	"headerCartButtonCounterAnimated": "PYQHADt",
	"updateCardCount": "YVqa6fm",
	"headerCartIcon": "VanZxeL"
};
export default ___CSS_LOADER_EXPORT___;
