import React from 'react';
import { CartCounter } from '../CartCounter';
import Styles from './TabBarCart.scss';

export const TabBarCart = () => (
  <CartCounter
    className={Styles.tabBarCart}
    classNameActive={Styles.tabBarCartAnimate}
  />
);
