import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { PriceBlock } from './PriceBlock';
import { PriceBlockProps } from './types';

export const PriceBlockProvider: FC<PriceBlockProps> = (
  transferProps
) => (
  <Provider store={StoreRegistry.instance.store}>
    <PriceBlock {...transferProps} />
  </Provider>
);
