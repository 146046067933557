import {
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit';
import type {
  CartItemUpdateProps,
  CartItemRemoveProps
} from '../../types/cart';
import { getCommonCartState } from '../../helpers/getCommonCartState/index';
import { sendCartEvent } from './helpers/sendCartEvent';
import { setCommonCartBrowserState } from './helpers/setCommonCartBrowserState';
import { setCartToken } from './helpers/setCartToken';
import { updateCartItem } from './reducers';
import { cartStoreName } from './consts';
import {
  type CartStore,
  type CartError,
  type CartProductIdsList,
  type CartCollectedReducer,
  type CartProductsList,
  type ProductItemId,
  CartStoreReducersKeys,
  CartStoreInitStates,
  ErrorType
} from './types';

export const getInitialState = (): CartStore => ({
  cartToken: null,
  totalCount: null,
  totalSum: null,
  products: null,
  cartSetTimestamp: 0,
  lastRequestTimestamp: 0,
  initState: CartStoreInitStates.awaitingInit,
  errorsList: [],
  loadersList: {},
  oldProductsState: getCommonCartState().products
});

const initialState = getInitialState();

const cartSlice = createSlice({
  name: cartStoreName,
  initialState,
  reducers: {
    addItemToCart: (
      state,
      { payload }: PayloadAction<CartItemUpdateProps>
    ) => {
      updateCartItem(
        state,
        payload.id,
        payload.newQuantity,
        CartStoreReducersKeys.addItem
      );
    },
    removeItemFromCart: (
      state,
      { payload }: PayloadAction<CartItemRemoveProps>
    ) => {
      updateCartItem(
        state,
        payload.id,
        0,
        !payload.isRevert &&
          CartStoreReducersKeys.removeItem,
        payload.traceId
      );
    },
    setCartItemQuantity: (
      state,
      { payload }: PayloadAction<CartItemUpdateProps>
    ) => {
      updateCartItem(
        state,
        payload.id,
        payload.newQuantity,
        !payload.isRevert &&
          CartStoreReducersKeys.setItemQuantity,
        payload.traceId
      );
    },
    increaseCartItemQuantity: (
      state,
      { payload }: PayloadAction<CartItemUpdateProps>
    ) => {
      updateCartItem(
        state,
        payload.id,
        payload.newQuantity,
        CartStoreReducersKeys.increaseItemQuantity,
        payload.traceId
      );
    },
    decreaseCartItemQuantity: (
      state,
      { payload }: PayloadAction<CartItemUpdateProps>
    ) => {
      updateCartItem(
        state,
        payload.id,
        payload.newQuantity,
        CartStoreReducersKeys.decreaseItemQuantity,
        payload.traceId
      );
    },
    removeItemsGroupFromCart: (
      state,
      { payload }: PayloadAction<CartProductIdsList>
    ) => {
      if (payload.length) {
        state.products = (state?.products || []).filter(
          (productItem) => !payload.includes(productItem.id)
        );
      } else {
        state.products = [];
      }
    },
    revertRemoveFromCart: (
      state,
      { payload }: PayloadAction<CartProductsList>
    ) => {
      state.products.push(...payload);
    },
    updateInitState: (
      state,
      {
        payload
      }: PayloadAction<{
        newInitStatus: CartStoreInitStates;
      }>
    ) => {
      state.initState = payload.newInitStatus;
    },
    setCartRequestData: (
      state,
      { payload }: PayloadAction<CartCollectedReducer>
    ) => {
      state.cartSetTimestamp = payload.timestamp;
      state.totalCount = payload.totalCount;
      state.totalSum = payload.totalSum;
      state.products = payload.products;

      const productsIds = payload.products.map(
        ({ id }) => id
      );

      setCartToken(payload.cartToken);
      setCommonCartBrowserState({
        productsCount: payload.products?.length || 0,
        productsItemsSumCount: payload.totalCount || 0,
        products: (payload.products || []).map(
          (productItem) => ({
            id: productItem.id,
            quantity: productItem.quantity
          })
        )
      });
      sendCartEvent(
        CartStoreReducersKeys.collector,
        productsIds.length === 1
          ? productsIds[0]
          : productsIds.join(),
        undefined,
        payload.traceId
      );
    },
    pushCartError: (
      state,
      { payload }: PayloadAction<CartError>
    ) => {
      state.errorsList.push(payload);

      sendCartEvent(CartStoreReducersKeys.pushError);
    },
    removeCartError: (
      state,
      {
        payload
      }: PayloadAction<{ productId: ProductItemId }>
    ) => {
      state.errorsList = state.errorsList.filter(
        ({ id }) => id !== payload.productId
      );
    },
    removeCartErrorByID: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.errorsList = state.errorsList.filter(
        ({ id }) => id !== payload
      );
    },
    clearCartItemTooltips: (
      state,
      {
        payload
      }: PayloadAction<{ productId: ProductItemId }>
    ) => {
      state.errorsList = state.errorsList.filter(
        ({ id, type }) =>
          id !== payload.productId &&
          type === ErrorType.tooltip
      );
    },
    setLastRequestTimestamp: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      if (state.lastRequestTimestamp < payload) {
        state.lastRequestTimestamp = payload;
      }
    },
    clearCart: (
      state,
      { payload }: PayloadAction<{ timestamp: number }>
    ) => {
      state.cartSetTimestamp = payload.timestamp;
      state.totalCount = 0;
      state.totalSum = 0;
      state.products = [];

      setCommonCartBrowserState({
        productsCount: 0,
        productsItemsSumCount: 0,
        products: []
      });
    },
    setLoadingState: (
      state,
      {
        payload
      }: PayloadAction<{
        id: ProductItemId;
        timestamp: number;
        status: boolean;
      }>
    ) => {
      if (
        payload.status &&
        (!state.loadersList[payload.id] ||
          state.loadersList[payload.id] <=
            payload.timestamp)
      ) {
        state.loadersList[payload.id] = payload.timestamp;
      } else if (
        !payload.status &&
        state.loadersList[payload.id] &&
        state.loadersList[payload.id] <= payload.timestamp
      ) {
        delete state.loadersList[payload.id];
      }
    }
  }
});

const cartReducers = cartSlice.reducer;
const cartActions = cartSlice.actions;

export { cartReducers, cartActions, initialState };
