import type { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import type { ProductItemId } from '../types';
import { cartActions } from '../slice';

export const requestLoadingSetter = async (
  id: ProductItemId,
  thunkApi: GetThunkAPI<unknown>
) => {
  const timestamp = Date.now();

  await thunkApi.dispatch(
    cartActions.setLastRequestTimestamp(timestamp)
  );
  await thunkApi.dispatch(
    cartActions.setLoadingState({
      id,
      timestamp,
      status: true
    })
  );

  return timestamp;
};

export const removeLoader = async (
  id: ProductItemId,
  timestamp: number,
  thunkApi: GetThunkAPI<unknown>
) => {
  await thunkApi.dispatch(
    cartActions.setLoadingState({
      id,
      timestamp,
      status: false
    })
  );
};
