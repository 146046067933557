import { v4 as uuid } from 'uuid';
import {
  CART_STORE_ADD_ITEM_EVENT_NAME,
  CART_STORE_REMOVE_ITEM_EVENT_NAME,
  CART_STORE_SET_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_INCREASE_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_DECREASE_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_REMOVE_ITEMS_GROUP_EVENT_NAME,
  CART_STORE_UPDATE_RESULT_EVENT_NAME,
  CART_STORE_CLEAR_EVENT_NAME,
  CART_STORE_CLEAR_FINALLY_EVENT_NAME
} from '../../consts/cartStoreUpdateEvent';
import type {
  CartItemUpdateProps,
  CartItemRemoveProps
} from '../../types/cart';
import type {
  CartClearProps,
  CartProductIdsList,
  CartUpdateReceiveEvent,
  CartClearPropsResult
} from '../../store/cart';
import type { CartStoreEvent } from '../../types/customEvents';
import { sendCustomEvent } from '../sendCustomEvent';
import { sendHandlesEvent } from './helpers';

export const addItemToCart = (
  updateData: CartItemUpdateProps
) =>
  sendHandlesEvent<CartItemUpdateProps>(
    CART_STORE_ADD_ITEM_EVENT_NAME,
    updateData
  );

export const removeItemFromCart = (
  updateData: CartItemRemoveProps
) =>
  sendHandlesEvent<CartItemRemoveProps>(
    CART_STORE_REMOVE_ITEM_EVENT_NAME,
    updateData
  );

export const setCartItemQuantity = (
  updateData: CartItemUpdateProps
) =>
  sendHandlesEvent<CartItemUpdateProps>(
    CART_STORE_SET_ITEM_QUANTITY_EVENT_NAME,
    updateData
  );

export const increaseCartItemQuantity = (
  updateData: CartItemUpdateProps
) =>
  sendHandlesEvent<CartItemUpdateProps>(
    CART_STORE_INCREASE_ITEM_QUANTITY_EVENT_NAME,
    updateData
  );

export const decreaseCartItemQuantity = (
  updateData: CartItemUpdateProps
) =>
  sendHandlesEvent<CartItemUpdateProps>(
    CART_STORE_DECREASE_ITEM_QUANTITY_EVENT_NAME,
    updateData
  );

export const removeCartItemsGroup = (
  updateData: CartProductIdsList
): Promise<CartUpdateReceiveEvent> =>
  new Promise((resolve, reject) => {
    const traceId = sendCustomEvent<CartProductIdsList>(
      CART_STORE_REMOVE_ITEMS_GROUP_EVENT_NAME,
      updateData
    );

    const handler = (
      event: CartStoreEvent<CartUpdateReceiveEvent>
    ) => {
      if (event.detail.traceId === traceId) {
        if (event.detail.error) {
          reject(event.detail.data);
        } else {
          resolve(event.detail.data);
        }

        document.removeEventListener(
          CART_STORE_UPDATE_RESULT_EVENT_NAME,
          handler
        );
      }
    };

    document.addEventListener(
      CART_STORE_UPDATE_RESULT_EVENT_NAME,
      handler
    );
  });

export const clearCart = (): Promise<boolean> =>
  new Promise((resolve, reject) => {
    const traceId = uuid();

    const clearCartHandler = (
      event: CustomEvent<CartClearPropsResult>
    ) => {
      if (event.detail.traceId === traceId) {
        if (event.detail.success) {
          resolve(true);
        } else {
          reject(false);
        }
      }
    };

    document.addEventListener(
      CART_STORE_CLEAR_FINALLY_EVENT_NAME,
      clearCartHandler
    );

    document.dispatchEvent(
      new CustomEvent<CartClearProps>(
        CART_STORE_CLEAR_EVENT_NAME,
        {
          detail: {
            traceId
          }
        }
      )
    );
  });
