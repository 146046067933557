import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getCartPriceNumber,
  getCartQuantityNumber
} from '../store/cart';
import { formatCurrency } from '../helpers/price';

export const useCommonCardBaseState = () => {
  const cartQuantity = useSelector(getCartQuantityNumber);
  const cartPrice = useSelector(getCartPriceNumber);

  const cartTitle = useMemo(() => {
    if (cartQuantity > 0) {
      return formatCurrency(cartPrice, true);
    }

    return 'Корзина';
  }, [cartQuantity, cartPrice]);

  const quantityLabel = useMemo(() => {
    if (cartQuantity > 0) {
      return cartQuantity > 99
        ? '99+'
        : String(cartQuantity);
    }

    return null;
  }, [cartQuantity]);

  return {
    cartTitle,
    quantityLabel,
    cartQuantity,
    cartPrice
  };
};
