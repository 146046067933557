// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WAMTyyw{display:inline-block;box-sizing:border-box;min-width:16px;height:16px;padding:0 4px;font-family:var(--control-font-family);font-size:12px;font-weight:600;font-style:normal;line-height:16px;text-align:center;color:#fff;border-radius:50px;background:#ff1f78}.RYXHFaw{animation:MtYx0QA 300ms linear}@keyframes MtYx0QA{0%{scale:0}100%{scale:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartCounterBubble": "WAMTyyw",
	"cartCounterBubbleAnimate": "RYXHFaw",
	"updateCardCount": "MtYx0QA"
};
export default ___CSS_LOADER_EXPORT___;
