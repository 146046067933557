const API_URLS_MAP: Record<string, string> = {
  'release.eapteka.ru':
    'https://apirelease.eapteka.ru/api/v3',
  'eapteka.ru': 'https://api.eapteka.ru/api/v3',
  'www.eapteka.ru': 'https://api.eapteka.ru/api/v3'
};

export const baseApiUrl: string = (() => {
  if (
    typeof location !== 'undefined' &&
    location.hostname in API_URLS_MAP
  ) {
    return API_URLS_MAP[location.hostname];
  }

  return typeof API_URL !== 'undefined'
    ? API_URL
    : '/api/v3';
})();

export const apiHeaders: Record<string, string> = {
  'Content-Type': 'application/json',
  'api-key':
    typeof API_KEY !== 'undefined'
      ? API_KEY
      : 'K<8RNCa^ft86LnNa',
  platform: 'frontend'
};

export const apiUrlsList = {
  getCart: '/web/cart/summary',
  clearCart: '/web/cart/clear',
  updateCart: '/web/cart/items'
};

export const ERRORS_CLEAR_INTERVAL = 1000;

export const REQUEST_ERRORS_TOOLTIP_DELAY = 1000;

export const SET_CART_DATA_DELAY = 1000;

export const CART_REQUEST_DELAY = 300;

export const GLOBAL_CARD_LINK = '/personal/cart/';

export const CART_INFO_LOCALSTORAGE_KEY =
  'eaCommonCartInfo';

export const USER_TOKEN_LOCALSTORAGE_KEY = 'user-token';
export const CART_TOKEN_LOCALSTORAGE_KEY = 'cart-token';
