import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import {
  REQUEST_ERRORS_TOOLTIP_DELAY,
  SET_CART_DATA_DELAY
} from '../../../config';
import type { TRootState } from '../../rootReducer';
import { sendCartEvent } from '../helpers/sendCartEvent';
import { removeLoader } from '../helpers/requestLoadingSetter';
import {
  type CartCollectedResponse,
  type CartCollectedResponseError,
  CartStoreReducersKeys,
  ErrorType,
  type ProductItemId
} from '../types';
import {
  getCartRequestTimestamp,
  getCartSettledTimestamp
} from '../selectors';
import { ALL_PRODUCTS_IDS, cartStoreName } from '../consts';
import { cartActions } from '../slice';

interface CartRequestHandlerProps {
  id?: ProductItemId;
  result:
    | CartCollectedResponse
    | CartCollectedResponseError;
  timestamp: number;
  revertCallback?: () => void;
  successCallback?: () => void;
  traceId?: string;
}

export const cartRequestHandler = createAsyncThunk(
  cartStoreName + '/cartRequestHandler',
  async (payload: CartRequestHandlerProps, thunkApi) => {
    const storeState = thunkApi.getState() as TRootState;
    const lastCartSetTimestamp =
      getCartSettledTimestamp(storeState);
    const lastCartRequestTimestamp =
      getCartRequestTimestamp(storeState);

    if (
      'status' in payload.result &&
      payload.result.status === 'error'
    ) {
      thunkApi.dispatch(
        cartActions.pushCartError({
          id: uuid(),
          type: ErrorType.tooltip,
          productId: payload?.id || null,
          message: payload.result.message,
          expires: Date.now() + REQUEST_ERRORS_TOOLTIP_DELAY
        })
      );

      if (payload.revertCallback) {
        payload.revertCallback();
      }
    } else {
      setTimeout(() => {
        if (
          payload.timestamp >= lastCartSetTimestamp &&
          payload.timestamp >= lastCartRequestTimestamp
        ) {
          thunkApi.dispatch(
            cartActions.setCartRequestData({
              ...(payload.result as CartCollectedResponse),
              timestamp: payload.timestamp,
              traceId: payload.traceId
            })
          );

          if (payload.successCallback) {
            payload.successCallback();
          }
        } else {
          let currentId = payload.id;

          if (!currentId) {
            const { products } =
              payload.result as CartCollectedResponse;
            const productsIds = products.map(
              ({ id }) => id
            );

            currentId =
              productsIds.length === 1
                ? productsIds[0]
                : productsIds.join();
          }

          sendCartEvent(
            CartStoreReducersKeys.collector,
            currentId,
            undefined,
            payload.traceId
          );
        }

        queueMicrotask(async () => {
          await removeLoader(
            payload.id || ALL_PRODUCTS_IDS,
            payload.timestamp,
            thunkApi
          );
        });
      }, SET_CART_DATA_DELAY);
    }
  }
);
