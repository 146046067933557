import type { TStore } from './store';
import type {
  CartItemUpdateProps,
  CartItemRemoveProps
} from '../types/cart';
import type { CartStoreEvent } from '../types/customEvents';
import {
  CART_STORE_ADD_ITEM_EVENT_NAME,
  CART_STORE_DECREASE_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_INCREASE_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_REMOVE_ITEM_EVENT_NAME,
  CART_STORE_SET_ITEM_QUANTITY_EVENT_NAME,
  CART_STORE_REMOVE_ITEMS_GROUP_EVENT_NAME,
  CART_STORE_CLEAR_EVENT_NAME,
  BX_CART_UPDATE
} from '../consts/cartStoreUpdateEvent';
import { errorClearInterval } from './cart/services/errorClearInterval';
import {
  type CartProductIdsList,
  type CartClearProps,
  fetchCart,
  addItemToCart,
  removeItemFromCart,
  setCartItemQuantity,
  increaseCartItemQuantity,
  decreaseCartItemQuantity,
  removeItemsGroupFromCart,
  clearCart
} from './cart';

export const storeInitSideEffects = (store: TStore) => {
  store.dispatch(fetchCart({ isInBackground: false }));

  errorClearInterval(store);

  document.addEventListener(
    CART_STORE_ADD_ITEM_EVENT_NAME,
    (event: CartStoreEvent<CartItemUpdateProps>) => {
      store.dispatch(
        addItemToCart({
          ...event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_REMOVE_ITEM_EVENT_NAME,
    (event: CartStoreEvent<CartItemRemoveProps>) => {
      store.dispatch(
        removeItemFromCart({
          ...event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_SET_ITEM_QUANTITY_EVENT_NAME,
    (event: CartStoreEvent<CartItemUpdateProps>) => {
      store.dispatch(
        setCartItemQuantity({
          ...event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_INCREASE_ITEM_QUANTITY_EVENT_NAME,
    (event: CartStoreEvent<CartItemUpdateProps>) => {
      store.dispatch(
        increaseCartItemQuantity({
          ...event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_DECREASE_ITEM_QUANTITY_EVENT_NAME,
    (event: CartStoreEvent<CartItemUpdateProps>) => {
      store.dispatch(
        decreaseCartItemQuantity({
          ...event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_REMOVE_ITEMS_GROUP_EVENT_NAME,
    (event: CartStoreEvent<CartProductIdsList>) => {
      store.dispatch(
        removeItemsGroupFromCart({
          ids: event.detail.data,
          traceId: event.detail.traceId
        })
      );
    }
  );
  document.addEventListener(
    CART_STORE_CLEAR_EVENT_NAME,
    (event: CustomEvent<CartClearProps>) => {
      store.dispatch(
        clearCart({
          traceId: event.detail?.traceId
        })
      );
    }
  );
  document.addEventListener(BX_CART_UPDATE, () => {
    store.dispatch(fetchCart({ isInBackground: false }));
  });
};
