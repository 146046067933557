// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sDbuSJh{--appCommonCardHeadCartBackground: #8168f0;--appCommonCardHeadCartHoverBackground: #6345dc;--appCommonCardHeadCartCounterBackground: #ff1f78;--appCommonCardHeadCartCounterColor: #fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appCommonCardThemeVars": "sDbuSJh"
};
export default ___CSS_LOADER_EXPORT___;
