import React, { FC } from 'react';
import { Button } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import type { BaseBuyButtonProps } from './types';
import Styles from './BaseBuyButton.scss';

export const BaseBuyButton: FC<BaseBuyButtonProps> = ({
  onClick,
  children,
  size = ComponentSizes.M,
  isWithAutoHeight = false
}) => (
  <Button
    className={clsx(Styles.baseBuyButton, {
      [Styles.baseBuyButtonXLarge]:
        size === ComponentSizes.XL,
      [Styles.baseBuyButtonLarge]:
        size === ComponentSizes.L,
      [Styles.baseBuyButtonMedium]:
        size === ComponentSizes.M,
      [Styles.baseBuyButtonSmall]:
        size === ComponentSizes.S,
      [Styles.baseBuyButtonAutoHeight]: isWithAutoHeight
    })}
    tag="button"
    size="s"
    onClick={onClick}
    fullWidth={true}
  >
    {children}
  </Button>
);
