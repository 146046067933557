// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gU-s0pa{width:100%;border-radius:24px}.Te34xwx{height:56px}.BfvLgY3{height:48px}.X2WBHy\\+{height:40px}._8ZfkZfV{height:32px}.-Zvu1xa{height:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyButtonSkeleton": "gU-s0pa",
	"buyButtonSkeletonXLarge": "Te34xwx",
	"buyButtonSkeletonLarge": "BfvLgY3",
	"buyButtonSkeletonMedium": "X2WBHy+",
	"buyButtonSkeletonSmall": "_8ZfkZfV",
	"buyButtonSkeletonAutoHeight": "-Zvu1xa"
};
export default ___CSS_LOADER_EXPORT___;
