import React from 'react';
import { CartCounter } from '../CartCounter';
import Styles from './CartCounterBubble.scss';

export const CartCounterBubble = () => (
  <CartCounter
    className={Styles.cartCounterBubble}
    classNameActive={Styles.cartCounterBubbleAnimate}
  />
);
