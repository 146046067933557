import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { TabBarCart } from './TabBarCart';

export const TabBarCartProvider: FC = () => (
  <Provider store={StoreRegistry.instance.store}>
    <TabBarCart />
  </Provider>
);
