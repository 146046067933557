import { createSelector } from '@reduxjs/toolkit';
import type { LocalStorageProductsList } from '../../types/cart';
import type { TRootState } from '../index';
import {
  type CartErrorsList,
  type CartProductsBaseItem,
  type CartProductsItem,
  type CartProductsList,
  type CartStore,
  type ProductItemId,
  CartStoreInitStates,
  type LoadersList
} from './types';
import { ALL_PRODUCTS_IDS } from './consts';

export const getCartState = (
  state: TRootState
): CartStore => state.cart;

export const getIsStorePending: (
  state: TRootState
) => boolean = createSelector(
  getCartState,
  (cartStore) =>
    cartStore.initState === CartStoreInitStates.pending
);

export const getIsStoreInit: (
  state: TRootState
) => boolean = createSelector(
  getCartState,
  (cartStore) =>
    cartStore.initState === CartStoreInitStates.init
);

export const getCartQuantityNumber: (
  state: TRootState
) => number = createSelector(
  getCartState,
  (cartStore) => cartStore.totalCount || 0
);

export const getCartPriceNumber: (
  state: TRootState
) => number = createSelector(
  getCartState,
  (cartStore) => cartStore.totalSum || 0
);

export const getErrorsList: (
  state: TRootState
) => CartErrorsList = createSelector(
  getCartState,
  (cartStore) => cartStore.errorsList
);

export const getCartItemsList: (
  state: TRootState
) => CartProductsList = createSelector(
  getCartState,
  (cartStore) => cartStore.products || []
);

export const getLoadersList: (
  state: TRootState
) => LoadersList = createSelector(
  getCartState,
  (cartStore) => cartStore.loadersList || {}
);

export const getCartTokenList: (
  state: TRootState
) => string | null = createSelector(
  getCartState,
  (cartStore) => cartStore.cartToken || null
);

export const getCartSettledTimestamp: (
  state: TRootState
) => number = createSelector(
  getCartState,
  (cartStore) => cartStore.cartSetTimestamp
);

export const getCartRequestTimestamp: (
  state: TRootState
) => number = createSelector(
  getCartState,
  (cartStore) => cartStore.lastRequestTimestamp
);

export type GetCartItemData = (
  productId: ProductItemId
) => (
  state: TRootState
) => CartProductsItem | CartProductsBaseItem | undefined;

export const getCartItemData: GetCartItemData = (
  productId: ProductItemId
) =>
  createSelector(getCartItemsList, (itemList) =>
    itemList.find(({ id }) => id === productId)
  );

export type GetCartItemErrorsList = (
  productId: ProductItemId
) => (state: TRootState) => CartErrorsList;

export const getCartItemErrorsList: GetCartItemErrorsList =
  (currentProductId: ProductItemId) =>
    createSelector(getErrorsList, (errorsList) =>
      errorsList.filter(
        ({ productId }) => currentProductId === productId
      )
    );

export type GetProductOnLoadingState = (
  productId: ProductItemId
) => (state: TRootState) => boolean;

export const getProductOnLoadingState: GetProductOnLoadingState =
  (productId: ProductItemId) =>
    createSelector(
      getLoadersList,
      (loadersList) =>
        ALL_PRODUCTS_IDS in loadersList ||
        productId in loadersList
    );

export const getOldProductsState: (
  state: TRootState
) => LocalStorageProductsList = createSelector(
  getCartState,
  (cartStore) => cartStore.oldProductsState
);

export const getOldProductItemState: (
  id: ProductItemId
) => (state: TRootState) => number | null = (id) =>
  createSelector(
    getOldProductsState,
    (products) =>
      products.find((productItem) => productItem.id === id)
        ?.quantity || null
  );
