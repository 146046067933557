import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BaseCommonCardItemStateProps } from '../../types/BaseCommonCardItemStateProps';
import { StoreRegistry } from '../../store';
import { CartPageStepper } from './CartPageStepper';

export const CartPageStepperProvider: FC<
  BaseCommonCardItemStateProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <CartPageStepper {...transferProps} />
  </Provider>
);
