import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { MobileMenuCartLink } from './MobileMenuCartLink';
import { MobileMenuCartLinkProps } from './types';

export const MobileMenuCartLinkProvider: FC<
  MobileMenuCartLinkProps
> = (transferProps) => (
  <Provider store={StoreRegistry.instance.store}>
    <MobileMenuCartLink {...transferProps} />
  </Provider>
);
