import React, { FC } from 'react';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BaseIconBuyButton } from '../BaseIconBuyButton';
import { IconBuyButtonProps } from './types';

export const IconBuyButton: FC<IconBuyButtonProps> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  customHandlers,
  eventsHandlers,
  size = ComponentSizes.S
}) => {
  const { addCartItemHandler } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers
  });

  return (
    <BaseIconBuyButton
      size={size}
      onClick={addCartItemHandler}
    />
  );
};
