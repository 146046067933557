// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RPlVerN{display:inline-flex;justify-content:center;align-items:center;margin:0;padding:8px;cursor:pointer;border:1px solid #8168f0;border-radius:50%;outline:none;background:#fff}.lAu5pwC{width:56px;height:56px}.ipyeLJ\\+{width:48px;height:48px}.Vzjxzwc{width:40px;height:40px}._4Slfb2x{width:32px;height:32px}.YyfbKGC{color:#8168f0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBuyButtonSuccess": "RPlVerN",
	"iconBuyButtonSuccessXLarge": "lAu5pwC",
	"iconBuyButtonSuccessLarge": "ipyeLJ+",
	"iconBuyButtonSuccessMedium": "Vzjxzwc",
	"iconBuyButtonSuccessSmall": "_4Slfb2x",
	"iconBuyButtonSuccessIcon": "YyfbKGC"
};
export default ___CSS_LOADER_EXPORT___;
