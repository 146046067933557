import React, { FC } from 'react';
import clsx from 'clsx';
import { Icon } from '@npm-registry/eapteka-ui';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { IconButtonSuccessProps } from './types';
import Styles from './IconButtonSuccess.scss';

export const IconButtonSuccess: FC<
  IconButtonSuccessProps
> = ({ size, onClick }) => (
  <button
    className={clsx(Styles.iconBuyButtonSuccess, {
      [Styles.iconBuyButtonSuccessXLarge]:
        size === ComponentSizes.XL,
      [Styles.iconBuyButtonSuccessLarge]:
        size === ComponentSizes.L,
      [Styles.iconBuyButtonSuccessMedium]:
        size === ComponentSizes.M,
      [Styles.iconBuyButtonSuccessSmall]:
        size === ComponentSizes.S
    })}
    onClick={onClick}
  >
    <Icon
      className={Styles.iconBuyButtonSuccessIcon}
      name="checkLarge"
      size="m"
    />
  </button>
);
