import React, { FC } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@npm-registry/eapteka-ui';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { formatCurrency } from '../../helpers/price';
import { BasePriceBlockProps } from './types';
import Styles from './BasePriceBlock.scss';

export const BasePriceBlock: FC<BasePriceBlockProps> = ({
  options: {
    size = ComponentSizes.M,
    isWithSale = true,
    isWithSaleBadge = true,
    isWithLabel = true,
    isVertical = false,
    isMobileView = false,
    isRedValue = false,
    label = 'Цена'
  } = {},
  customClasses = {},
  isLoading = false,
  discountPercent,
  oldPrice,
  price
}) => {
  const isLarge =
    size === ComponentSizes.L || size === ComponentSizes.XL;
  const isMedium = size === ComponentSizes.M;
  const isSmall = size === ComponentSizes.S;
  const isDiscountExist = Boolean(
    price && oldPrice && Math.abs(oldPrice - price) > 0
  );

  return (
    <div
      className={clsx(Styles.basePriceBlock, {
        [Styles.basePriceBlockVertical]: isVertical,
        [customClasses.container]: customClasses.container
      })}
    >
      {!isLoading && isWithSale && isDiscountExist && (
        <div
          className={clsx(Styles.basePriceBlockDiscount, {
            [Styles.basePriceBlockDiscountLarge]: isLarge,
            [Styles.basePriceBlockDiscountMedium]: isMedium,
            [Styles.basePriceBlockDiscountSmall]: isSmall,
            [Styles.basePriceBlockDiscountVertical]:
              isVertical,
            [Styles.basePriceBlockDiscountMobileView]:
              isMobileView,
            [Styles.basePriceBlockDiscountWithSaleBadge]:
              isWithSaleBadge,
            [customClasses.discountBlock]:
              customClasses.discountBlock
          })}
        >
          {isWithSaleBadge && Boolean(discountPercent) && (
            <span
              className={clsx(
                Styles.basePriceBlockDiscountBadge,
                {
                  [Styles.basePriceBlockDiscountBadgeLarge]:
                    isLarge,
                  [Styles.basePriceBlockDiscountBadgeMedium]:
                    isMedium,
                  [Styles.basePriceBlockDiscountBadgeSmall]:
                    isSmall,
                  [Styles.basePriceBlockDiscountBadgeVertical]:
                    isVertical,
                  [Styles.basePriceBlockDiscountBadgeMobileView]:
                    isMobileView,
                  [customClasses.discountBadge]:
                    customClasses.discountBadge
                }
              )}
            >
              -{discountPercent}%
            </span>
          )}
          <span
            className={clsx(
              Styles.basePriceBlockDiscountPrice,
              {
                [Styles.basePriceBlockDiscountPriceLarge]:
                  isLarge,
                [Styles.basePriceBlockDiscountPriceMedium]:
                  isMedium,
                [Styles.basePriceBlockDiscountPriceSmall]:
                  isSmall,
                [Styles.basePriceBlockDiscountPriceMobileView]:
                  isMobileView,
                [customClasses.discountPrice]:
                  customClasses.discountPrice
              }
            )}
          >
            {formatCurrency(oldPrice || 0, true)}
          </span>
        </div>
      )}
      <div
        className={clsx(Styles.basePriceBlockPrice, {
          [Styles.basePriceBlockPriceVertical]: isVertical,
          [customClasses.priceBlock]:
            customClasses.priceBlock
        })}
      >
        {Boolean(price) && !isLoading && (
          <>
            {isWithLabel && (
              <span
                className={clsx(
                  Styles.basePriceBlockPriceLabel,
                  {
                    [Styles.basePriceBlockPriceLabelLarge]:
                      isLarge,
                    [Styles.basePriceBlockPriceLabelMedium]:
                      isMedium,
                    [Styles.basePriceBlockPriceLabelSmall]:
                      isSmall,
                    [Styles.basePriceBlockPriceLabelMobileView]:
                      isMobileView,
                    [customClasses.priceLabel]:
                      customClasses.priceLabel
                  }
                )}
              >
                {label}
              </span>
            )}
            <span
              className={clsx(
                Styles.basePriceBlockPriceValue,
                {
                  [Styles.basePriceBlockPriceValueLarge]:
                    isLarge,
                  [Styles.basePriceBlockPriceValueMedium]:
                    isMedium,
                  [Styles.basePriceBlockPriceValueSmall]:
                    isSmall,
                  [Styles.basePriceBlockPriceValueRed]:
                    isRedValue,
                  [Styles.basePriceBlockPriceValueMobileView]:
                    isMobileView,
                  [customClasses.priceValue]:
                    customClasses.priceValue
                }
              )}
            >
              {formatCurrency(price || 0, true)}
            </span>
          </>
        )}
        {isLoading && (
          <Skeleton
            className={clsx(
              Styles.basePriceBlockPricePlaceholder,
              {
                [Styles.basePriceBlockPricePlaceholderLarge]:
                  isLarge,
                [Styles.basePriceBlockPricePlaceholderMedium]:
                  isMedium,
                [Styles.basePriceBlockPricePlaceholderSmall]:
                  isSmall,
                [Styles.basePriceBlockPricePlaceholderMobileView]:
                  isMobileView,
                [customClasses.skeleton]:
                  customClasses.skeleton
              }
            )}
          />
        )}
      </div>
    </div>
  );
};
