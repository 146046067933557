import { CART_INFO_LOCALSTORAGE_KEY } from '../../../config';
import { LocalStorageState } from '../../../types/cart';

export const setCommonCartBrowserState = (
  newState: LocalStorageState
) => {
  localStorage.setItem(
    CART_INFO_LOCALSTORAGE_KEY,
    JSON.stringify(newState)
  );
};
