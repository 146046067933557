import React, { FC } from 'react';
import clsx from 'clsx';
import { Icon } from '@npm-registry/eapteka-ui';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BaseIconBuyButtonProps } from './types';
import Styles from './BaseIconBuyButton.scss';

export const BaseIconBuyButton: FC<
  BaseIconBuyButtonProps
> = ({ size = ComponentSizes.S, ...props }) => {
  const isButton = 'onClick' in props;
  const ButtonTag = isButton ? 'button' : 'a';

  return (
    <ButtonTag
      className={clsx(Styles.baseIconBuyButton, {
        [Styles.baseIconBuyButtonXLarge]:
          size === ComponentSizes.XL,
        [Styles.baseIconBuyButtonLarge]:
          size === ComponentSizes.L,
        [Styles.baseIconBuyButtonMedium]:
          size === ComponentSizes.M,
        [Styles.baseIconBuyButtonSmall]:
          size === ComponentSizes.S
      })}
      {...(isButton
        ? { onClick: props.onClick }
        : { href: props.href })}
    >
      <Icon
        className={Styles.baseIconBuyButtonImage}
        name="cart"
        size="m"
      />
    </ButtonTag>
  );
};
