import { StoreRegistry } from '../store';
import { fetchCart } from '../store/cart';

export const updateCart = () => {
  StoreRegistry.instance.store.dispatch(
    fetchCart({ isInBackground: true })
  );
};

export default updateCart;
