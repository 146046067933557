/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';
import type {
  CartItemRemoveProps,
  CartItemUpdateProps
} from '../../types/cart';
import {
  requestCartItemGroupRemove,
  requestClearCart
} from '../../services/api/methods/cart';
import { CART_STORE_CLEAR_FINALLY_EVENT_NAME } from '../../consts/cartStoreUpdateEvent';
import { REQUEST_ERRORS_TOOLTIP_DELAY } from '../../config';
import type { TRootState } from '../rootReducer';
import {
  removeLoader,
  requestLoadingSetter
} from './helpers/requestLoadingSetter';
import { updateItemQuantity } from './thunks/updateItemQuantity';
import { cartRequestHandler } from './thunks/cartRequestHandler';
import { addItemToCart } from './thunks/addItemToCart';
import { fetchCart } from './thunks/fetchCart';
import {
  type CartClearPropsResult,
  type CartError,
  type CartProductIdsList,
  ErrorType
} from './types';
import { getCartItemsList } from './selectors';
import { ALL_PRODUCTS_IDS, cartStoreName } from './consts';
import { cartActions } from './slice';

export const removeItemsGroupFromCart = createAsyncThunk(
  cartStoreName + '/removeItemsGroupFromCartThunk',
  async (
    {
      ids,
      traceId
    }: { ids: CartProductIdsList; traceId: string },
    thunkApi
  ) => {
    const removeProducts = getCartItemsList(
      thunkApi.getState() as TRootState
    ).filter(({ id }) => ids.includes(id));
    const timestamp = Date.now();

    await thunkApi.dispatch(
      ids.length === 1
        ? cartActions.removeItemFromCart({
            id: ids[0],
            traceId
          })
        : cartActions.removeItemsGroupFromCart(ids)
    );
    const result = await requestCartItemGroupRemove({
      id: ids
    });

    thunkApi.dispatch(
      cartRequestHandler({
        id: ids.join(''),
        timestamp,
        result,
        traceId,
        revertCallback: () => {
          thunkApi.dispatch(
            cartActions.revertRemoveFromCart(removeProducts)
          );
        }
      })
    );
  }
);

export const removeItemFromCart = createAsyncThunk(
  cartStoreName + '/removeItemFromCartThunk',
  async (payload: CartItemRemoveProps, thunkApi) => {
    await thunkApi.dispatch(
      removeItemsGroupFromCart({
        ids: [payload.id],
        traceId: payload.traceId
      })
    );
  }
);

export const setCartItemQuantity = createAsyncThunk(
  cartStoreName + '/setCartItemQuantityThunk',
  async (payload: CartItemUpdateProps, thunkApi) => {
    await thunkApi.dispatch(updateItemQuantity(payload));
    await thunkApi.dispatch(
      cartActions.setCartItemQuantity(payload)
    );
  }
);

export const increaseCartItemQuantity = createAsyncThunk(
  cartStoreName + '/increaseCartItemQuantityThunk',
  async (payload: CartItemUpdateProps, thunkApi) => {
    await thunkApi.dispatch(updateItemQuantity(payload));
    await thunkApi.dispatch(
      cartActions.increaseCartItemQuantity(payload)
    );
  }
);

export const decreaseCartItemQuantity = createAsyncThunk(
  cartStoreName + '/decreaseCartItemQuantityThunk',
  async (payload: CartItemUpdateProps, thunkApi) => {
    await thunkApi.dispatch(updateItemQuantity(payload));
    await thunkApi.dispatch(
      cartActions.decreaseCartItemQuantity(payload)
    );
  }
);

export const pushCartError = createAsyncThunk(
  cartStoreName + '/pushCartErrorThunk',
  async (payload: CartError, thunkApi) => {
    await thunkApi.dispatch(
      cartActions.pushCartError(payload)
    );
  }
);

export const clearCart = createAsyncThunk(
  cartStoreName + '/clearCartThunk',
  async (
    { traceId = uuid() }: { traceId?: string },
    thunkApi
  ) => {
    const timestamp = await requestLoadingSetter(
      ALL_PRODUCTS_IDS,
      thunkApi
    );

    try {
      await requestClearCart();

      await thunkApi.dispatch(
        cartActions.clearCart({
          timestamp: timestamp
        })
      );

      await removeLoader(
        ALL_PRODUCTS_IDS,
        timestamp,
        thunkApi
      );

      document.dispatchEvent(
        new CustomEvent<CartClearPropsResult>(
          CART_STORE_CLEAR_FINALLY_EVENT_NAME,
          {
            detail: {
              traceId,
              success: true
            }
          }
        )
      );
    } catch (error) {
      await removeLoader(
        ALL_PRODUCTS_IDS,
        timestamp,
        thunkApi
      );

      if (error?.message) {
        await thunkApi.dispatch(
          cartActions.pushCartError({
            id: ALL_PRODUCTS_IDS,
            type: ErrorType.notification,
            productId: null,
            message: error?.message,
            expires:
              Date.now() + REQUEST_ERRORS_TOOLTIP_DELAY
          })
        );
      }

      document.dispatchEvent(
        new CustomEvent<CartClearPropsResult>(
          CART_STORE_CLEAR_FINALLY_EVENT_NAME,
          {
            detail: {
              traceId,
              success: false
            }
          }
        )
      );
    }
  }
);

export { addItemToCart, fetchCart };
