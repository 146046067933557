import React, { FC } from 'react';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { getProductOnLoadingState } from '../../store/cart';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BaseBuyStepper } from '../BaseBuyStepper';
import { BuyStepperProps } from './types';
import { useSelector } from 'react-redux';

export const BuyStepper: FC<BuyStepperProps> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  size = ComponentSizes.M,
  isWithItemPrice,
  price,
  isRemoveAvailable = false,
  isUseLoading = true,
  customHandlers,
  eventsHandlers
}) => {
  const isProductLoading = useSelector(
    getProductOnLoadingState(id)
  );
  const {
    setCartItemQuantityHandler,
    increaseCartItemQuantityHandler,
    decreaseCartItemQuantityHandler,
    isLoading,
    isUpdating,
    quantity,
    price: currentPrice,
    productItemErrors
  } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers,
    price
  });

  return (
    <BaseBuyStepper
      isRemoveAvailable={isRemoveAvailable}
      isUseLoading={isUseLoading}
      isLoading={isLoading}
      isUpdating={isUpdating}
      isProductLoading={isProductLoading}
      availableCount={availableCount}
      size={size}
      quantity={quantity}
      productItemErrors={productItemErrors}
      isWithItemPrice={isWithItemPrice}
      price={currentPrice}
      setCartItemQuantityHandler={
        setCartItemQuantityHandler
      }
      increaseCartItemQuantityHandler={
        increaseCartItemQuantityHandler
      }
      decreaseCartItemQuantityHandler={
        decreaseCartItemQuantityHandler
      }
    />
  );
};
