// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._10NvIOe{display:inline-block;width:100%}.Zt935Ri{display:inline-flex;place-items:stretch flex-start;box-sizing:border-box;width:100%;font-size:18px;font-weight:600;line-height:24px;color:#333f48;border:1px solid #dee1eb;border-radius:50px;background-color:#fff}.l--akNt{height:56px}.d0hTmZ3{height:48px;font-size:16px;line-height:20px}.qr\\+flhP{height:40px}.bDmar6n{height:32px}.pFzXi43{border:1px solid #ff1f78;background:#fdeef6}.\\+tJ53nF{display:flex;align-items:center;height:auto;margin:0;padding:0 8px;border:none;outline:none;background:none;color:#333f48}.gvkkAva,.\\+tJ53nF:disabled{opacity:.4}.\\+tJ53nF:hover{cursor:pointer}.EKdqwLI,._606K8JS{padding:0 15px}.n1swbTY,.F2xvdWh{padding:0 7px}@media(max-width: 400px){.n1swbTY,.F2xvdWh{padding:0 4px}}.L7bOZF3{width:100%;font-family:var(--display-font-family);font-size:inherit;font-weight:normal;line-height:1.25;text-align:center;border:0;border-radius:0;outline-width:0;background-color:rgba(0,0,0,0);transition:outline-width .1s ease-in}.L7bOZF3:focus{outline:2px solid #dee1eb}.L7bOZF3:read-only{pointer-events:none}.o5KKK5w{display:inline-flex;justify-content:center;align-items:center;width:100%;height:100%}.Y2xkpyv{animation:_3aFy5MY .3s linear}.wn59tAF{animation:lwiuBJz .3s linear}.nSGFrzf>div{z-index:1}@keyframes _3aFy5MY{from{opacity:0}to{opacity:1}}@keyframes lwiuBJz{from{opacity:1}to{opacity:0}}.w8XD8j0{height:15px;margin-top:4px;font-family:var(--control-font-family);font-size:12px;font-weight:normal;line-height:15px;text-align:center;color:#9199a3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseBuyStepperContainer": "_10NvIOe",
	"baseBuyStepper": "Zt935Ri",
	"baseBuyStepperXLarge": "l--akNt",
	"baseBuyStepperLarge": "d0hTmZ3",
	"baseBuyStepperMedium": "qr+flhP",
	"baseBuyStepperSmall": "bDmar6n",
	"baseBuyStepperError": "pFzXi43",
	"baseBuyStepperButton": "+tJ53nF",
	"baseBuyStepperButtonDisable": "gvkkAva",
	"baseBuyStepperButtonXLarge": "EKdqwLI",
	"baseBuyStepperButtonLarge": "_606K8JS",
	"baseBuyStepperButtonMedium": "n1swbTY",
	"baseBuyStepperButtonSmall": "F2xvdWh",
	"baseBuyStepperInput": "L7bOZF3",
	"baseBuyStepperUpdateLoader": "o5KKK5w",
	"baseBuyStepperTooltipFadeIn": "Y2xkpyv",
	"fadeIn": "_3aFy5MY",
	"baseBuyStepperTooltipFadeOut": "wn59tAF",
	"fadeOut": "lwiuBJz",
	"baseBuyStepperTooltip": "nSGFrzf",
	"baseBuyStepperPriceBlock": "w8XD8j0"
};
export default ___CSS_LOADER_EXPORT___;
