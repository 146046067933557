import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { HeaderCart } from './HeaderCart';

export const HeaderCartProvider: FC = () => (
  <Provider store={StoreRegistry.instance.store}>
    <HeaderCart />
  </Provider>
);
