import cartSummaryBasicList from './data/cartSummaryBasicList';
import { CartSummaryMockKey } from './types';

export const mocksMap: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in CartSummaryMockKey]: any;
} = {
  [CartSummaryMockKey.basicList]: cartSummaryBasicList,
  [CartSummaryMockKey.bypass]: null
};
