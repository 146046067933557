import { v4 as uuid } from 'uuid';
import * as eventsNames from '../consts/cartStoreUpdateEvent';

// eslint-disable-next-line @typescript-eslint/ban-types
export const sendCustomEvent = <EventData extends Object>(
  eventName: (typeof eventsNames)[keyof typeof eventsNames],
  eventData: EventData,
  traceId: string = uuid()
) => {
  document.dispatchEvent(
    new CustomEvent(eventName, {
      detail: {
        traceId,
        data: eventData
      }
    })
  );

  return traceId;
};
