import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { CartCounterNumber } from './CartCounterNumber';

export const CartCounterNumberProvider: FC = () => (
  <Provider store={StoreRegistry.instance.store}>
    <CartCounterNumber />
  </Provider>
);
