// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kS7LGVA{display:inline-flex;justify-content:center;align-items:center;width:100%;height:100%;animation:dnMKQfF 1s linear infinite}@keyframes dnMKQfF{0%{opacity:1}50%{opacity:.3}100%{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "kS7LGVA",
	"loaderBlink": "dnMKQfF"
};
export default ___CSS_LOADER_EXPORT___;
