import { createAsyncThunk } from '@reduxjs/toolkit';
import { CartItemUpdateProps } from '../../../types/cart';
import { addCartItemRequest } from '../../../services/api/methods/cart';
import { requestLoadingSetter } from '../helpers/requestLoadingSetter';
import { cartStoreName } from '../consts';
import { cartActions } from '../slice';
import { cartRequestHandler } from './cartRequestHandler';

export const addItemToCart = createAsyncThunk(
  cartStoreName + '/addItemToCartThunk',
  async (payload: CartItemUpdateProps, thunkApi) => {
    await thunkApi.dispatch(
      cartActions.addItemToCart(payload)
    );

    const timestamp = await requestLoadingSetter(
      payload.id,
      thunkApi
    );
    const result = await addCartItemRequest([payload]);

    thunkApi.dispatch(
      cartRequestHandler({
        id: payload.id,
        result,
        timestamp,
        revertCallback: () => {
          thunkApi.dispatch(
            cartActions.removeItemFromCart({
              id: payload.id
            })
          );
        }
      })
    );
  }
);
