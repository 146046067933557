import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { CartCounterBubble } from './CartCounterBubble';

export const CartCounterBubbleProvider: FC = () => (
  <Provider store={StoreRegistry.instance.store}>
    <CartCounterBubble />
  </Provider>
);
