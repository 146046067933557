import React, { FC } from 'react';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { BuyStepper } from '../BuyStepper/BuyStepper';
import { BuyButtonSkeleton } from '../BuyButtonSkeleton';
import { BaseBuyButton } from '../BaseBuyButton';
import { Loader } from '../Loader';
import { BuyButtonWithStepperProps } from './types';

export const BuyButtonWithStepper: FC<
  BuyButtonWithStepperProps
> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  customHandlers,
  eventsHandlers,
  size = ComponentSizes.M
}) => {
  const {
    addCartItemHandler,
    isLoading,
    isUpdating,
    quantity
  } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers
  });

  if (isLoading) {
    return <BuyButtonSkeleton size={size} />;
  }

  return (
    <>
      {Boolean(quantity) && (
        <BuyStepper
          id={id}
          unitQuantity={unitQuantity}
          availableCount={availableCount}
          size={size}
          isUseLoading={false}
        />
      )}
      {!quantity && (
        <BaseBuyButton
          onClick={addCartItemHandler}
          size={size}
        >
          {!isUpdating && 'В корзину'}
          {isUpdating && <Loader />}
        </BaseBuyButton>
      )}
    </>
  );
};
