export default {
  products_count: 7,
  products_price: 33000,
  items: [
    {
      id: '1',
      ean: '001',
      quantity: 200,
      price: 2,
      old_price: 3,
      discount: 30,
      isBenefitERecipe: true
    },
    {
      id: '2',
      ean: '002',
      quantity: 101,
      price: 22,
      old_price: 31,
      discount: 10,
      isBenefitERecipe: false
    },
    {
      id: '3',
      ean: '003',
      quantity: 99,
      price: 2810,
      old_price: 3120,
      discount: 10,
      isBenefitERecipe: false
    },
    {
      id: '4',
      ean: '004',
      quantity: 40,
      price: 28100,
      old_price: 31200,
      discount: 10,
      isBenefitERecipe: false
    },
    {
      id: '5',
      ean: '005',
      quantity: 25,
      price: 281000,
      old_price: 312000,
      discount: 10,
      isBenefitERecipe: false
    },
    {
      id: '6',
      ean: '006',
      quantity: 7,
      price: 281000,
      old_price: 312000,
      discount: 10,
      isBenefitERecipe: false
    },
    {
      id: '7',
      ean: '007',
      quantity: 1,
      price: 2810000,
      old_price: 3120000,
      discount: 10,
      isBenefitERecipe: false
    }
  ]
};
