import React, { FC } from 'react';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { IconButtonSuccess } from '../IconButtonSuccess';
import { BaseIconBuyButton } from '../BaseIconBuyButton';
import { IconBuyButtonPropsAnimated } from './types';

export const IconBuyButtonAnimated: FC<
  IconBuyButtonPropsAnimated
> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  customHandlers,
  eventsHandlers,
  size = ComponentSizes.S
}) => {
  const {
    quantity,
    addCartItemHandler,
    removeItemFromCartHandler
  } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers
  });

  if (quantity > 0) {
    return (
      <IconButtonSuccess
        onClick={removeItemFromCartHandler}
        size={size}
      />
    );
  }

  return (
    <BaseIconBuyButton
      onClick={addCartItemHandler}
      size={size}
    />
  );
};
