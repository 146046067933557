import type { LocalStorageProductsList } from '../../types/cart';

export type ProductItemId = string;

export interface UpdateRequestsItem {
  uuid: string;
  timestamp: number;
}

export type UpdateRequestsItemList = UpdateRequestsItem[];

export interface UpdateRequestsItemReducer
  extends UpdateRequestsItem {
  state: boolean;
}

export interface CartProductsItem {
  id: ProductItemId;
  quantity: number;
  price: number;
  oldPrice: number;
  discount: number;
  isBenefitERecipe: boolean;
}

export interface CartProductsBaseItem {
  id: ProductItemId;
  quantity: number;
  price: null;
  oldPrice: null;
  discount: null;
  isBenefitERecipe: null;
}

export type CartProductsList = (
  | CartProductsItem
  | CartProductsBaseItem
)[];

export type CartProductIdsList = ProductItemId[];

export interface CartCollectedResponse {
  cartToken: string | null;
  totalCount: number | null;
  totalSum: number | null;
  products: CartProductsList | null;
}

export interface CartCollectedReducer
  extends CartCollectedResponse {
  timestamp: number;
  traceId?: string;
}

export interface CartCollectedResponseError {
  status: string;
  code: string;
  message: string;
}

export enum ErrorType {
  tooltip = 'tooltip',
  notification = 'notification'
}

export interface CartError {
  id: string;
  type: ErrorType;
  productId: ProductItemId;
  message: string;
  expires?: number;
}

export type CartErrorsList = CartError[];

export enum CartStoreInitStates {
  awaitingInit = 'awaitingInit',
  pending = 'pending',
  init = 'init'
}

export interface LoadersList {
  [key: ProductItemId]: number;
}

export interface CartStore extends CartCollectedResponse {
  initState: CartStoreInitStates;
  cartSetTimestamp: number;
  lastRequestTimestamp: number;
  errorsList: CartErrorsList;
  loadersList: LoadersList;
  oldProductsState: LocalStorageProductsList;
}

export enum CartStoreReducersKeys {
  collector = 'collector',
  pushError = 'pushError',
  removeError = 'removeError',
  addItem = 'addItem',
  removeItem = 'removeItem',
  setItemQuantity = 'setItemQuantity',
  increaseItemQuantity = 'increaseItemQuantity',
  decreaseItemQuantity = 'decreaseItemQuantity'
}

export type SendCartEvent = CustomEvent<{
  traceId: string;
  data: {
    action: CartStoreReducersKeys;
    newQuantity?: number | null;
    id?: ProductItemId | null;
  };
}>;

export interface CartItemReceiveEvent {
  newState: CartProductsItem;
  prevState: CartProductsItem | null;
}

export interface CartUpdateReceiveEvent {
  newState: CartProductsItem[];
  prevState: CartProductsItem[] | null;
}

export interface CartClearProps {
  traceId: string;
}
export interface CartClearPropsResult
  extends CartClearProps {
  success: boolean;
}

export type ClearCartEvent =
  CustomEvent<CartClearPropsResult>;
