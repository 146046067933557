// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m51JSUr{display:inline-flex;justify-content:center;align-items:center;width:100%;height:auto;margin:0;cursor:pointer;border:2px solid #8168f0;border-radius:50px;outline:none;background:#fff}.c67CEPc{height:56px;padding:16px}.a5BWbRP{height:48px;padding:12px 16px}.ZwpBxG4{height:40px;padding:8px 16px}.S1e0Ryn{height:32px;padding:4px 16px}.N9EbCsa{height:100%}.TRadh8c{color:#8168f0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buyButtonSuccess": "m51JSUr",
	"buyButtonSuccessXLarge": "c67CEPc",
	"buyButtonSuccessLarge": "a5BWbRP",
	"buyButtonSuccessMedium": "ZwpBxG4",
	"buyButtonSuccessSmall": "S1e0Ryn",
	"buyButtonSuccessAutoHeight": "N9EbCsa",
	"buyButtonSuccessIcon": "TRadh8c"
};
export default ___CSS_LOADER_EXPORT___;
