import { createAsyncThunk } from '@reduxjs/toolkit';
import { cartGetRequestError } from '../../../services/dispatchers';
import { requestCart } from '../../../services/api/methods/cart';
import { getCommonCartState } from '../../../helpers/getCommonCartState/index';
import { mockSelector } from '../collector/mock/mockSelector';
import {
  type CartCollectedResponse,
  CartStoreInitStates
} from '../types';
import { cartStoreName } from '../consts';
import { cartActions } from '../slice';
import { pushCartError } from '../actionCreators';
import { getAvailableCountError } from '../errors';

export interface FetchCartProps {
  isInBackground: boolean;
}

export const fetchCart = createAsyncThunk(
  cartStoreName + '/fetchCartThunk',
  async ({ isInBackground }: FetchCartProps, thunkApi) => {
    const timestamp = Date.now();

    if (!isInBackground) {
      thunkApi.dispatch(
        cartActions.updateInitState({
          newInitStatus: CartStoreInitStates.pending
        })
      );
    }

    requestCart.abortRequest();

    const sendResult = (result: CartCollectedResponse) => {
      const oldState = getCommonCartState().products;

      if (!isInBackground) {
        thunkApi.dispatch(
          cartActions.updateInitState({
            newInitStatus: CartStoreInitStates.init
          })
        );
      }

      oldState.forEach(({ id, quantity }) => {
        const productItem = result.products.find(
          (product) => product.id === id
        );

        if (
          productItem &&
          productItem.quantity < quantity
        ) {
          thunkApi.dispatch(
            pushCartError(getAvailableCountError(id))
          );
        }
      });

      thunkApi.dispatch(
        cartActions.setCartRequestData({
          ...result,
          timestamp
        })
      );
    };

    try {
      const cartRes: CartCollectedResponse =
        await mockSelector(requestCart);

      sendResult(cartRes);
    } catch (error) {
      cartGetRequestError.dispatch();

      if (!isInBackground) {
        sendResult({
          cartToken: null,
          totalCount: 0,
          totalSum: 0,
          products: []
        });
      }
    }
  }
);
