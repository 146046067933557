import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestCartItemsChange } from '../../../services/api/methods/cart';
import { CART_REQUEST_DELAY } from '../../../config';
import type { CartItemUpdateProps } from '../../../types/cart';
import type { TRootState } from '../../rootReducer';
import { requestLoadingSetter } from '../helpers/requestLoadingSetter';
import { getCartItemData } from '../selectors';
import {
  CartCollectedResponse,
  type ProductItemId
} from '../types';
import { cartStoreName } from '../consts';
import { cartActions } from '../slice';
import { getNotEnoughInStockError } from '../errors';
import { cartRequestHandler } from './cartRequestHandler';

const updateItemsTimeouts: Map<
  ProductItemId,
  {
    timeout: NodeJS.Timeout;
    oldQuantity: number;
  }
> = new Map();

export const updateItemQuantity = createAsyncThunk(
  cartStoreName + '/updateItemQuantity',
  async (payload: CartItemUpdateProps, thunkApi) => {
    const timestamp = await requestLoadingSetter(
      payload.id,
      thunkApi
    );

    const store = thunkApi.getState() as TRootState;
    const cartItem = getCartItemData(payload.id)(store);

    const updateTimeout = updateItemsTimeouts.get(
      payload.id
    );

    if (updateTimeout) {
      clearTimeout(updateTimeout.timeout);
      updateItemsTimeouts.delete(payload.id);
    }

    const request = async () => {
      const result = await requestCartItemsChange(payload);
      const currentTimeout = updateItemsTimeouts.get(
        payload.id
      );

      thunkApi.dispatch(
        cartRequestHandler({
          id: payload.id,
          result,
          timestamp,
          revertCallback: () => {
            thunkApi.dispatch(
              cartActions.setCartItemQuantity({
                id: payload.id,
                newQuantity:
                  currentTimeout.oldQuantity ||
                  updateTimeout.oldQuantity,
                isRevert: true,
                traceId: payload.traceId
              })
            );
          },
          successCallback: () => {
            const newCartItem = (
              result as CartCollectedResponse
            ).products.find(({ id }) => id == payload.id);

            if (
              payload.newQuantity > newCartItem.quantity
            ) {
              thunkApi.dispatch(
                cartActions.pushCartError(
                  getNotEnoughInStockError(
                    payload.id,
                    newCartItem.quantity
                  )
                )
              );
            }
          }
        })
      );
    };

    updateItemsTimeouts.set(payload.id, {
      oldQuantity:
        updateTimeout?.oldQuantity || cartItem.quantity,
      timeout: setTimeout(request, CART_REQUEST_DELAY)
    });
  }
);
