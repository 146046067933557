// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PztqcER{position:relative;display:block;padding:0 1rem 0 3.75rem;font-size:.938rem;font-weight:normal;line-height:45px;text-decoration:none;color:#fff}.PztqcER:hover{color:#fff}.\\+Afiv9Y{position:absolute;top:0;left:20px;display:flex;align-items:center;height:100%;font-size:1.25rem}.drpQNXr{text-decoration:none}.drpQNXr:hover{text-decoration:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenuCartLink": "PztqcER",
	"mobileMenuCartLinkIcon": "+Afiv9Y",
	"mobileMenuCartLinkLabel": "drpQNXr"
};
export default ___CSS_LOADER_EXPORT___;
