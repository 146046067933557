import { apiUrlsList } from '../../../../config';
import {
  CartCollectedResponse,
  CartCollectedResponseError,
  CartProductIdsList
} from '../../../../store/cart';
import { getRegionId } from '../../../../helpers/getRegion/getRegionId';
import { CartItemUpdateProps } from '../../../../types/cart';
import { apiService } from '../../apiService';

export const requestCart = apiService.createGetMethod<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  CartCollectedResponse
>(apiUrlsList.getCart, {
  mapRequest: () => ({
    headers: {
      'region-id': getRegionId()
    }
  })
});

export const addCartItemRequest =
  apiService.createPostMethod<
    CartItemUpdateProps[],
    CartCollectedResponse | CartCollectedResponseError
  >(apiUrlsList.updateCart, {
    mapRequest: (body) => ({
      headers: {
        'region-id': getRegionId()
      },
      body: JSON.stringify({
        products: body.map(({ id, newQuantity }) => ({
          id,
          quantity: newQuantity
        }))
      })
    })
  });

export const requestCartItemsChange =
  apiService.createPatchMethod<
    CartItemUpdateProps,
    CartCollectedResponse | CartCollectedResponseError
  >(apiUrlsList.updateCart, {
    mapRequest: ({ id, newQuantity }, { currentUrl }) => ({
      headers: {
        'region-id': getRegionId()
      },
      url: `${currentUrl}/${id}`,
      body: JSON.stringify({ quantity: newQuantity })
    })
  });

export const requestCartItemGroupRemove =
  apiService.createDeleteMethod<
    { id: CartProductIdsList },
    CartCollectedResponse | CartCollectedResponseError
  >(apiUrlsList.updateCart, {
    mapRequest: (body) => ({
      headers: {
        'region-id': getRegionId()
      },
      body: JSON.stringify(body)
    })
  });

export const requestClearCart = apiService.createPostMethod<
  // eslint-disable-next-line @typescript-eslint/ban-types
  {},
  [] | CartCollectedResponseError
>(apiUrlsList.clearCart, {
  mapRequest: () => ({
    headers: {
      'region-id': getRegionId()
    }
  })
});
