import React, { FC } from 'react';
import { Skeleton } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BuyButtonSkeletonProps } from './types';
import Styles from './BuyButtonSkeleton.scss';

export const BuyButtonSkeleton: FC<
  BuyButtonSkeletonProps
> = ({ isWithAutoHeight, size = ComponentSizes.M }) => (
  <Skeleton
    className={clsx(Styles.buyButtonSkeleton, {
      [Styles.buyButtonSkeletonXLarge]:
        size === ComponentSizes.XL,
      [Styles.buyButtonSkeletonLarge]:
        size === ComponentSizes.L,
      [Styles.buyButtonSkeletonMedium]:
        size === ComponentSizes.M,
      [Styles.buyButtonSkeletonSmall]:
        size === ComponentSizes.S,
      [Styles.buyButtonSkeletonAutoHeight]: isWithAutoHeight
    })}
  />
);
