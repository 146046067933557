import {
  CART_STORE_CLEAR_FINALLY_EVENT_NAME,
  CART_STORE_UPDATE_EVENT_NAME
} from '../../consts/cartStoreUpdateEvent';
import {
  ClearCartEvent,
  SendCartEvent
} from '../../store/cart';

export const subscribeToCartUpdates = (
  eventListener: (
    event: SendCartEvent | ClearCartEvent
  ) => void
) => {
  document.addEventListener(
    CART_STORE_UPDATE_EVENT_NAME,
    eventListener
  );
  document.addEventListener(
    CART_STORE_CLEAR_FINALLY_EVENT_NAME,
    eventListener
  );

  return () => {
    document.removeEventListener(
      CART_STORE_UPDATE_EVENT_NAME,
      eventListener
    );
    document.removeEventListener(
      CART_STORE_CLEAR_FINALLY_EVENT_NAME,
      eventListener
    );
  };
};

export default subscribeToCartUpdates;
