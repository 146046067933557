import { createRestApiPreset } from '@npm-registry/helpers/request';
import { baseApiUrl, apiHeaders } from '../../config';
import { getTokensFromStorage } from './helpers/getTokensFromStorage';

export const apiService = createRestApiPreset(baseApiUrl, {
  credentials: 'include',
  redirect: 'follow',
  headers: {
    ...apiHeaders,
    ...getTokensFromStorage()
  }
});
