// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LMZw9bP{display:inline-block;width:100px;height:32px}._6YBRAt-{display:inline-flex;place-items:stretch flex-start;box-sizing:border-box;width:100px;height:32px;font-size:.938rem;line-height:2.4;color:#333f48;border:1px solid #dee1eb;border-radius:30px;background-color:#fff}.G8lP9TV{border:1px solid #ff1f78;background:#fdeef6}.KgOzoEB{display:flex;align-items:center;height:100%;margin:0;padding:0 4px;border:none;outline:none;background:none}._5j21QSO{border-top-right-radius:30px;border-bottom-right-radius:30px}._5JQA\\+s1{border-top-left-radius:30px;border-bottom-left-radius:30px}.KgOzoEB:disabled{pointer-events:none;opacity:.4}.KgOzoEB:hover{cursor:pointer}._7Bi7F4X{width:100%;font-family:var(--display-font-family);font-size:16px;font-weight:600;line-height:20px;text-align:center;border:0;border-radius:0;outline-width:0;background-color:rgba(0,0,0,0);transition:outline-width .1s ease-in}._7Bi7F4X:focus{outline:2px solid #dee1eb}._7Bi7F4X:read-only{pointer-events:none}.b74miop{display:inline-flex;justify-content:center;align-items:center;width:100%;height:100%;animation:RdAIuJZ 1s linear infinite}@keyframes RdAIuJZ{0%{opacity:1}50%{opacity:.3}100%{opacity:1}}.g0xarK8{height:15px;margin-top:4px;font-family:var(--control-font-family);font-size:12px;font-weight:normal;line-height:15px;text-align:center;color:#9199a3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cartPageStepperContainer": "LMZw9bP",
	"cartPageStepper": "_6YBRAt-",
	"cartPageStepperError": "G8lP9TV",
	"cartPageStepperButton": "KgOzoEB",
	"cartPageStepperButtonUp": "_5j21QSO",
	"cartPageStepperButtonDown": "_5JQA+s1",
	"cartPageStepperInput": "_7Bi7F4X",
	"cartPageStepperUpdateLoader": "b74miop",
	"loaderBlink": "RdAIuJZ",
	"cartPageStepperPriceBlock": "g0xarK8"
};
export default ___CSS_LOADER_EXPORT___;
