import {
  CART_TOKEN_LOCALSTORAGE_KEY,
  USER_TOKEN_LOCALSTORAGE_KEY
} from '../../../config';

export const getTokensFromStorage = () => {
  const userToken = localStorage.getItem(
    USER_TOKEN_LOCALSTORAGE_KEY
  );
  const cartToken = localStorage.getItem(
    CART_TOKEN_LOCALSTORAGE_KEY
  );

  const resultTokensMap: {
    'user-token'?: string;
    'cart-token'?: string;
  } = {};

  if (userToken) {
    resultTokensMap['user-token'] = userToken;
  }

  if (cartToken) {
    resultTokensMap['cart-token'] = cartToken;
  }

  return resultTokensMap;
};
