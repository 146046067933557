// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".exj7doN{position:absolute;top:4px;left:calc(100% - 6px);display:inline;box-sizing:border-box;min-width:16px;height:16px;padding:0 4px;font-family:var(--control-font-family);font-size:12px;font-weight:normal;font-style:normal;line-height:16px;text-align:center;color:#fff;border-radius:50px;background:#ff1f78}.R6cN4rt{animation:_5EyFl4h 300ms linear}@keyframes _5EyFl4h{0%{scale:0}100%{scale:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabBarCart": "exj7doN",
	"tabBarCartAnimate": "R6cN4rt",
	"updateCardCount": "_5EyFl4h"
};
export default ___CSS_LOADER_EXPORT___;
