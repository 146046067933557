import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { BuyStepper } from './BuyStepper';
import { BuyStepperProps } from './types';

export const BuyStepperProvider: FC<BuyStepperProps> = (
  transferProps
) => (
  <Provider store={StoreRegistry.instance.store}>
    <BuyStepper {...transferProps} />
  </Provider>
);
