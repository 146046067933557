import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { StoreRegistry } from '../../store';
import { BuyButton } from './BuyButton';
import { BuyButtonProps } from './types';

export const BuyButtonProvider: FC<BuyButtonProps> = (
  transferProps
) => (
  <Provider store={StoreRegistry.instance.store}>
    <BuyButton {...transferProps} />
  </Provider>
);
