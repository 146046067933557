import React, { FC } from 'react';
import { useCommonCardItemState } from '../../hooks/useCommonCardItemState';
import { ComponentSizes } from '../../consts/componentsViewVariables';
import { BaseBuyButton } from '../BaseBuyButton';
import { BuyButtonProps } from './types';

export const BuyButton: FC<BuyButtonProps> = ({
  id,
  unitQuantity = 1,
  availableCount = Infinity,
  href,
  onAdd,
  customHandlers,
  eventsHandlers,
  isWithAutoHeight,
  size = ComponentSizes.M
}) => {
  const { addCartItemHandler } = useCommonCardItemState({
    id,
    unitQuantity,
    availableCount,
    customHandlers,
    eventsHandlers
  });

  // TODO: добавить колбек для отправки onAdd после добавления
  const onClickHandler = () => {
    addCartItemHandler();

    if (href) {
      location.href = href;
    } else if (onAdd) {
      onAdd();
    }
  };

  return (
    <BaseBuyButton
      onClick={onClickHandler}
      isWithAutoHeight={isWithAutoHeight}
      size={size}
    >
      В корзину
    </BaseBuyButton>
  );
};
