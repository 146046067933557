import {
  configureStore,
  type PreloadedState
} from '@reduxjs/toolkit';
import {
  rootReducer,
  type TRootState
} from './rootReducer';
import { storeInitSideEffects } from './storeInitSideEffects';

export const configureCoreStore = (
  initialValues?: PreloadedState<TRootState>
) => {
  const store = configureStore({
    reducer: rootReducer,
    devTools: {
      name: 'cartStore'
    },
    preloadedState: initialValues
  });

  storeInitSideEffects(store);

  return store;
};

export type TStore = ReturnType<typeof configureCoreStore>;
export type TStoreDispatch = ReturnType<
  typeof configureCoreStore
>['dispatch'];
