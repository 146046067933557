// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Anq1qpR{display:inline-flex;justify-content:center;align-items:center;margin:0;padding:0;cursor:pointer;border:0;border-radius:50%;outline:0;background:#8168f0}.Anq1qpR:hover{background:#6345dc}.Anq1qpR:active{background:#563fa3}.Anq1qpR:disabled{background:#8168f0}.hDCd1fk{width:56px;height:56px}.V38UxqU{width:48px;height:48px}.m4eqrAn{width:40px;height:40px}.lX8F3yZ{width:32px;height:32px}.Cy5Ap\\+0{width:16px;height:16px;stroke:#fff;stroke-width:1px;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseIconBuyButton": "Anq1qpR",
	"baseIconBuyButtonXLarge": "hDCd1fk",
	"baseIconBuyButtonLarge": "V38UxqU",
	"baseIconBuyButtonMedium": "m4eqrAn",
	"baseIconBuyButtonSmall": "lX8F3yZ",
	"baseIconBuyButtonImage": "Cy5Ap+0"
};
export default ___CSS_LOADER_EXPORT___;
