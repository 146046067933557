import React from 'react';
import { Icon } from '@npm-registry/eapteka-ui';
import clsx from 'clsx';
import { GLOBAL_CARD_LINK } from '../../config';
import AppTheme from '../../vars.scss';
import { CartCounter } from '../CartCounter';
import Styles from './HeaderCart.scss';

export const HeaderCart = () => {
  return (
    <div
      className={clsx(
        Styles.headerCart,
        AppTheme.appCommonCardThemeVars
      )}
    >
      <a
        className={Styles.headerCartButton}
        href={GLOBAL_CARD_LINK}
      >
        <span
          className={
            Styles.headerCartButtonCounterContainer
          }
        >
          <Icon
            className={Styles.headerCartIcon}
            name="cart"
            size="m"
          />
          <CartCounter
            className={Styles.headerCartButtonCounter}
            classNameActive={
              Styles.headerCartButtonCounterAnimated
            }
          />
        </span>
        Корзина
      </a>
    </div>
  );
};
